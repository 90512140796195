import { storage, auth } from '../../firebase/Firebase';
import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';
import * as types from './types';
import store from '..';

const selectDeleteType = (type) => {
    var deleteType;
    if (type == 'transcript') {
        deleteType = types.DELETE_TEACHER_TRANSCRIPT 
    } else if (type == 'reference letter') {
        deleteType = types.DELETE_TEACHER_REFERENCE_LETTER
    } else if (type == 'resume') {
        deleteType = types.DELETE_TEACHER_RESUME
    }
    return deleteType
}

const selectFailureType = (type) => {
    var failureType;
    if (type == 'transcript') {
        failureType = types.TEACHER_TRANSCRIPT_DOC_DELETE_FAILURE
    } else if (type == 'reference letter') {
        failureType = types.TEACHER_REFERENCE_LETTER_DOC_DELETE_FAILURE
    } else if (type == 'resume') {
        failureType = types.TEACHER_RESUME_DOC_DELETE_FAILURE
    }
    return failureType
}

export const deleteDocument = async (type, docId) => {
    const { uid } = auth.currentUser;
    const config = await getRequestConfig();
    var folder, filePath;
    if (type == 'transcript') {
        folder = 'transcripts'
    } else if (type == 'reference letter') {
        folder = 'references'
    } else if (type == 'resume') {
        folder = 'resumes'
    }
    filePath = `${folder}/${uid}/${docId}.pdf`;
    storage
        .ref(filePath)
        .delete()
        .then(() => {
            // Now we need to make call(s) to django to remove the document from the db
            django.delete(`/attachments/id/${docId}/`, config)
                .then((resp) => {
                    store.dispatch({ type: selectDeleteType(type), payload: docId });
                })
                .catch(() => {
                    store.dispatch({ type: selectFailureType(type) })
                })
        })
        .catch(error => {
            if(error.code == 'storage/object-not-found') {
                // The object doesn't exist in Storage, try deleting it from the db
                django.delete(`/attachments/id/${docId}/`, config)
                .then((resp) => {
                    store.dispatch({ type: selectDeleteType(type), payload: docId });
                })
            } else {
                store.dispatch({ type: selectFailureType(type) })
            }
        });
}