import React from 'react';
import { connect } from 'react-redux';
import { Alert, Form, ButtonToolbar } from 'react-bootstrap';
import { useFormik } from 'formik';
import {
  changeTitle,
  changeLink,
  changeDescription,
  createNewJob,
  cancelNewJob,
} from '../../state/actions/newJobForm';
import CustomButton from '../CustomButton/CustomButton';
import styles from './NewJobPosting.styles';
import JobPostingForm from '../../components/JobPostingForm/JobPostingForm';
import { validationSchema } from '../../components/JobPostingForm/FormikValues'

const initialValues = {
  title: '',
  description: '',
  selectedLicensure: [],
  selectedPartOrFullTime: [],
  selectedQuestions: [],
  selectedGradeLevels: '',
  expirationDate: '',
  externalLink: '',
};

// Why is this a functional component? Because we want to use useFormik(). Why do we
// want to use useFormik()? Because we want to be able to know about state changes
// in a context where we also have access to the "formik" object. If we use
// <Formik> and class-based components, then there's no way that I know of to do that.
function NewJobPosting (props) {
  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: async (jobValues, actions) => {
      const createJobSuccessful = await props.createNewJob(
        jobValues.title,
        jobValues.description,
        jobValues.selectedQuestions,
        jobValues.selectedLicensure,
        jobValues.selectedGradeLevels,
        props.employerData,
        props.externalEmployerData,
        jobValues.selectedPartOrFullTime,
        jobValues.externalLink,
        jobValues.expirationDate,
      );
      if(createJobSuccessful) {
        actions.resetForm();
      }
      actions.setSubmitting(false);
    }
  });

  // props.showNewPostingForm goes from true to false when the "Cancel" button is clicked.
  // We need to reset the form when that happens, but only once. formik.dirty will be true
  // if any of the field values deviate from initial state. Once the reset has taken place,
  // formik.dirty will be false, so even though the component is updated/reloads upon
  // reset, we do not call formik.resetForm again and avoid creating an infinite loop.
  if (props.showNewPostingForm == '' && formik.dirty) {
    formik.resetForm();
  }

  const renderAlert = () => {
    if (props.backendError != '') {
      return (
        <Alert variant="danger">
          Sorry, there was an error posting your new job posting. Please check your entries and try again.
        </Alert>
      );
    }
    return null;
  };

  return (
    <div style={styles.formCenterStyle}>
      {renderAlert()}
      <Form
        noValidate
        onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <JobPostingForm props={formik} />
        <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
          <CustomButton
            type="submit"
            isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Submitting new job...' : 'Submit new job'}
        </CustomButton>
        </ButtonToolbar>
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({
  questions: state.employer.questions,
  backendError: state.newJobForm.backendError,
  showNewPostingForm: state.newJobForm.showNewPostingForm,
});

export default connect(
  mapStateToProps,
  {
    changeTitle,
    changeLink,
    changeDescription,
    createNewJob,
    cancelNewJob,
  }
)(NewJobPosting);
