import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { renameDocument } from '../../state/actions/teacherSettings';
import CustomButton from '../CustomButton/CustomButton';

class TeacherUploadRenameForm extends Component {
    state = {
        newDocName: '',
    }
    onRenameClick = () => {
      if (!this.props.inModal) {
        const { uploadInfo } = this.props;
        if (this.props.renameDocument(
          uploadInfo.folderForRename,
          uploadInfo.docIdForRename,
          this.state.newDocName.substr(0, 255),
          this.props.inModal
        )) {
          this.setState({ newDocName: '' })
        }
      } else {
        var folder
        var type = this.props.type
        if (type == 'transcript') folder = "transcripts"
        else if (type == 'reference letter') folder = "references"
        else if (type == 'resume') folder = "resumes"

        if (this.props.renameDocument(
          folder,
          this.props.docId,
          this.state.newDocName.substr(0, 255),
          this.props.inModal
        )) {
          this.setState({ newDocName: '' })
        }
      }
    }

    renderRenameFormControl = (isRenaming, inModal) => {
      var instructionText,buttonText, initialValue;
      // The button text for this control needs to vary based on the situation.
      // Are we naming a file that was just uploaded? Or are we renaming a file that
      // was uploaded previously?
      // Likewise, the placeholder text in the text input should be vary based on
      // the situation.
      if (!inModal) {
        instructionText = ''
        buttonText = isRenaming ? 'Naming...' : 'Name'
        initialValue = this.state.newDocName
      } else {
        instructionText = (
          <Form.Label>Enter a new name for your document</Form.Label>
        )
        buttonText = isRenaming ? 'Renaming...' : 'Rename'
        initialValue = this.props.name
      }
      return (
        <div>
          <Form.Group controlId="formDocumentRename">
              {instructionText}
              <Form.Control
              placeholder={!inModal ? "Name your document..." : initialValue}
              onChange={e => this.setState({ newDocName: e.target.value })}
              />
          </Form.Group>
          <CustomButton onClick={this.onRenameClick}>
            {buttonText}
          </CustomButton>
        </div>
      )
    }

    render = () => {
        const { uploadInfo, type, inModal } = this.props;
        if (!uploadInfo && !inModal) {
            return <div />;
        }
        const { uploadDone, isRenamingNew, renameNewDone, renameNewFailed,
                isRenamingExisting, renameExistingDone, renameExistingFailed } = uploadInfo;
        if (!inModal) {
          if (renameNewDone) {
            return <h3>Successfully uploaded and named {type}.</h3>;
          } else if (renameNewFailed) {
            return (
              <div>
                <h3>Error - Rename of {type} failed. Please try again.</h3>
                {this.renderRenameFormControl(isRenamingNew)}
              </div>
            );
          } else if (uploadDone && !isRenamingNew) {
            return (
              <div>
                <h3>Success! Please give your new {type} a name.</h3>
                {this.renderRenameFormControl(isRenamingNew)}
              </div>
            );
          } else {
            return <div />;
          }
        } else {
          if (renameExistingDone) {
            return <h3>Successfully renamed {type}.</h3>;
          } else if (renameExistingFailed) {
            return (
              <div>
                <h3>Error - Rename of {type} failed. Please try again.</h3>
                {this.renderRenameFormControl(isRenamingExisting, inModal)}
              </div>
            );
          } else {
            return this.renderRenameFormControl(isRenamingExisting, inModal)
          }
        }
    };
}

const selectorName = (state, type) => {
  if (type == 'transcript') return state.teacherTranscripts
  else if (type == 'reference letter') return state.teacherRefLetters
  else if (type == 'resume') return state.teacherResumes
  else return null
}

const mapStateToProps = (state, ownProps) => ({
    user: state.auth.user, // user.uid is user id
    uploadInfo: selectorName(state, ownProps.type)
  });
    
  export default connect(
    mapStateToProps,
    {
      renameDocument
    }
  )(TeacherUploadRenameForm);