import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  description: yup
    .string()
    .required(),
  selectedLicensure: yup
    .array().min(1)
    .required(),
  selectedPartOrFullTime: yup
    .array().nullable(),
  selectedQuestions: yup
    .array().nullable(),
  selectedGradeLevels: yup
    .array().nullable(),
  expirationDate: yup
    // This works, but it allows for invalid dates, like 13/13/13
    .string().matches(/^\d{2}\/\d{2}\/\d{2}$/),
  externalLink: yup
    .string().nullable(),
});