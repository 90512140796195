import * as types from '../actions/types';

const INITIAL_STATE = {
    uploads: null,
    gettingTeacherTrans: false,
    gettingTeacherTransFailed: false,
    uploadError: null,
    uploadProgress: null,
    uploadStarted: false,
    folderForRename: null,
    docIdForRename: null,
    isRenaming: false,
    uploadDone: false,
    renameDone: false,
    renameFailed: false,
    deleteDone: false,
    deleteFailed: false
};

// 0: id
// 1: docid (firebase)
// 2: filename
// 3: downloadURL
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_TEACHER_TRANCRIPT:
            return { ...state, gettingTeacherTrans: true };
        case types.GET_TEACHER_TRANCRIPT_SUCCESS:
            return { ...state, uploads: action.payload, gettingTeacherTrans: false };
        case types.GET_TEACHER_TRANCRIPT_FAIL:
            return { ...state, gettingTeacherTrans: false, gettingTeacherTransFailed: true };
        case types.UPLOAD_TEACHER_TRANSCRIPT_SUCCESS: {
            const transcripts = state.uploads.slice();
            transcripts.push(action.payload.fileInfo)
            return { ...state, uploads: transcripts, uploadError: false,
                folderForRename: action.payload.folder,
                docIdForRename: action.payload.docId,
                uploadDone: true,
                renameDone: false };
        }
        case types.UPLOAD_TEACHER_TRANSCRIPT_STARTED: {
            return { ...state, uploadStarted: true, renameDone: false, renameNewDone: false };
        }
        case types.UPLOAD_TEACHER_TRANSCRIPT_PROGRESS: {
            // If an upload has started, it's okay to update progress
            // otherwise, ignore this call and just return current state
            return state.uploadStarted ? 
                { ...state, uploadProgress: action.payload }
                : { ...state}
        }
        case types.UPLOAD_TEACHER_TRANSCRIPT_STOPPED: {
            return { ...state, uploadStarted: false, uploadProgress: null, uploadError: null };
        }
        case types.UPLOAD_TEACHER_TRANSCRIPT_FAIL: {
            return { ...state, uploadError: true }
        }
        case types.RENAME_NEW_TEACHER_TRANSCRIPT: {
            // payload here is: {docId, newName}
            const transcripts = state.uploads.slice();
            return { ...state, isRenamingNew: false, renameNewDone: true, renameNewFailed: false,
                uploadDone: false, uploads: transcripts.map( doc => {
                // payload.docId is the uuid making up the filename on Firebase
                // that value is stored in state in index 0 of the array
                // array index 2 in the array in state is the friendly name of the file
                if (doc[1] == action.payload.docId) {
                    doc[2] = action.payload.newName
                }
                return doc
            })}
        }
        case types.RENAME_EXISTING_TEACHER_TRANSCRIPT: {
            // payload here is: {docId, newName}
            const transcripts = state.uploads.slice();
            return { ...state, isRenamingExisting: false, renameExistingDone: true, renameExistingFailed: false,
                uploads: transcripts.map( doc => {
                // payload.docId is the uuid making up the filename on Firebase
                // that value is stored in state in index 0 of the array
                // array index 2 in the array in state is the friendly name of the file
                if (doc[1] == action.payload.docId) {
                    doc[2] = action.payload.newName
                }
                return doc
            })}
        }
        case types.TEACHER_NEW_TRANSCRIPT_START_DOC_RENAME:
            return { ...state, isRenamingNew: true, renameNewDone: false, renameNewFailed: false };
        case types.TEACHER_EXISTING_TRANSCRIPT_START_DOC_RENAME:
            return { ...state, isRenamingExisting: true, renameExistingDone: false, renameExistingFailed: false };
        case types.TEACHER_NEW_TRANSCRIPT_DOC_RENAME_FAILURE:
            return { ...state, isRenamingNew: false, renameNewDone: false, renameNewFailed: true };
        case types.TEACHER_EXISTING_TRANSCRIPT_DOC_RENAME_FAILURE:
            return { ...state, isRenamingExisting: false, renameExistingDone: false, renameExistingFailed: true };
        case types.TEACHER_TRANSCRIPT_DOC_RENAME_MODAL_CLOSED:
            return { ...state, isRenamingExisting: false, renameExistingDone: false, renameExistingFailed: false };
        case types.TEACHER_TRANSCRIPT_DOC_DELETE_MODAL_CLOSED:
            return { ...state, deleteDone: false, deleteFailed: false };
        case types.DELETE_TEACHER_TRANSCRIPT: {
            // payload here is an array, where:
            // 0 is the id in the DB
            // 1 is the docId per Firebase
            // 2 is the name
            // 3 is the download URL
            // So filter out the entry where position 1 matches the provided docId
            // from the payload.
            const remainingTranscripts = state.uploads.filter(doc => doc[1] != action.payload)
            return { ...state, deleteDone: true, uploads: remainingTranscripts }
        }
        case types.TEACHER_TRANSCRIPT_DOC_DELETE_FAILURE: {
            return { ...state, deleteFailed: true };
        }
        default:
            return state;
    }
};
