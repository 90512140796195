import django from '../../apis/django';
import { auth } from '../../firebase/Firebase';
import { getTeacher } from './getTeachers.js';
import * as types from './types';
import store from '..';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const renameDocument = (folder, docId, newName, inModal) => async dispatch => {
  const selectorName = (folder, renameStatus, inModal) => {
    if (!inModal) {
      if (folder == 'transcripts') {
        if (renameStatus == 'start') {
          return types.TEACHER_NEW_TRANSCRIPT_START_DOC_RENAME
        } else if (renameStatus == 'update') {
          return types.RENAME_NEW_TEACHER_TRANSCRIPT
        } else if (renameStatus == 'failure') {
          return types.TEACHER_NEW_TRANSCRIPT_DOC_RENAME_FAILURE
        }
      } else if (folder == 'references') {
        if (renameStatus == 'start') {
          return types.TEACHER_NEW_REFERENCE_LETTER_START_DOC_RENAME
        } else if (renameStatus == 'update') {
          return types.RENAME_NEW_TEACHER_REFERENCE_LETTER
        } else if (renameStatus == 'failure') {
          return types.TEACHER_NEW_REFERENCE_LETTER_DOC_RENAME_FAILURE
        }
      } else if (folder == 'resumes') {
        if (renameStatus == 'start') {
          return types.TEACHER_NEW_RESUME_START_DOC_RENAME
        } else if (renameStatus == 'update') {
          return types.RENAME_NEW_TEACHER_RESUME
        } else if (renameStatus == 'failure') {
          return types.TEACHER_NEW_RESUME_DOC_RENAME_FAILURE
        }
      }
    } else {
      if (folder == 'transcripts') {
        if (renameStatus == 'start') {
          return types.TEACHER_EXISTING_TRANSCRIPT_START_DOC_RENAME
        } else if (renameStatus == 'update') {
          return types.RENAME_EXISTING_TEACHER_TRANSCRIPT
        } else if (renameStatus == 'failure') {
          return types.TEACHER_EXISTING_TRANSCRIPT_DOC_RENAME_FAILURE
        }
      } else if (folder == 'references') {
        if (renameStatus == 'start') {
          return types.TEACHER_EXISTING_REFERENCE_LETTER_START_DOC_RENAME
        } else if (renameStatus == 'update') {
          return types.RENAME_EXISTING_TEACHER_REFERENCE_LETTER
        } else if (renameStatus == 'failure') {
          return types.TEACHER_EXISTING_REFERENCE_LETTER_DOC_RENAME_FAILURE
        }
      } else if (folder == 'resumes') {
        if (renameStatus == 'start') {
          return types.TEACHER_EXISTING_RESUME_START_DOC_RENAME
        } else if (renameStatus == 'update') {
          return types.RENAME_EXISTING_TEACHER_RESUME
        } else if (renameStatus == 'failure') {
          return types.TEACHER_EXISTING_RESUME_DOC_RENAME_FAILURE
        }
      }
    }
  }

  try {
    dispatch({ type: selectorName(folder, 'start', inModal) });
    // https://www.django-rest-framework.org/api-guide/generic-views/#updatemodelmixin
    // Also provides a .partial_update(request, *args, **kwargs) method, which is similar
    // to the update method, except that all fields for the update will be optional. This
    // allows support for HTTP PATCH requests.
    const config = await getRequestConfig();
    await django.patch(`/attachments/id/${docId}/`,
      {
        fileName: newName
      },
      config
    )
    // Update the filename in state, too, and show as completed
    dispatch({ type: selectorName(folder, 'update', inModal), payload: {docId, newName}})
    return true
  } catch (err) {
    console.error(err);
    console.error('There was a problem trying to update the name for the attachment.');
    dispatch({ type: selectorName(folder, 'failure', inModal) });
    return false
  }
};

export const editSetting = async (teacherValues) => {
  try {
    const { uid } = auth.currentUser;

    // Based on the logic behind the field dependencies on the Teacher Settings page,
    // clear out fields that should have no value.
    if (!teacherValues.hasTeachingLicense) {
      // The teacher does not have a license
      teacherValues.teachingLicenseType = [];
      teacherValues.hasArTeachingLicense = false;
      teacherValues.selectedLicensure = [];
    } else {
      // The teacher has a license
      teacherValues.towardsTeachingLicense = false;
      teacherValues.willToWorkTowardsLicense = false;
      teacherValues.expectedLicenseDate = "";
    }
    if (teacherValues.hasArTeachingLicense) {
      // Teacher is licensed in AR
      teacherValues.licensureAndState = "";
    }
    if (teacherValues.towardsTeachingLicense) {
      // Teacher is already working towards license
      teacherValues.willToWorkTowardsLicense = false;
    } else {
      // Teacher is not working towards license
      teacherValues.expectedLicenseDate = "";
      teacherValues.teachingLicenseWorkingTowards = "";
    }
    if (!teacherValues.violationOfLaw) {
      teacherValues.violationOfLawExplain = "";
    }
    if (!teacherValues.revokedCertificate) {
      teacherValues.revokedCertificateExplain = "";
    }
    if (!teacherValues.sexuallyAbusedChild) {
      teacherValues.sexuallyAbusedChildExplain = "";
    }
    if (!teacherValues.substantiatedChildAbuseCharge) {
      teacherValues.substantiatedChildAbuseChargeExplain = "";
    }
    if (!teacherValues.hasBeenFired) {
      teacherValues.hasBeenFiredExplain = "";
    }

    let convertedTeacherValues = teacherValues;

    // We have to stringify selectedLicensure, teachingLicenseType, and
    // teachingLicenseWorkingTowards to save them in the backend database as
    // strings. django will complain if we try to send it a Javascript object.
    if (teacherValues.selectedLicensure) {
      convertedTeacherValues.selectedLicensure = JSON.stringify(teacherValues.selectedLicensure)
    }
    if (teacherValues.teachingLicenseType) {
      convertedTeacherValues.teachingLicenseType = JSON.stringify(teacherValues.teachingLicenseType)
    }
    if (teacherValues.teachingLicenseWorkingTowards) {
      convertedTeacherValues.teachingLicenseWorkingTowards = JSON.stringify(teacherValues.teachingLicenseWorkingTowards)
    }

    // We have to get the teacher's ID in django by looking them up by their
    // Firebase auth ID. Then we can construct a teacher object and post it
    // to django
    // We HAVE to do that, we can't send in the be the firebaseAuthId and expect it
    // to work. We're depending on the default ViewSet update() action - we didn't
    // write our own. That expects to be passed the primary key.
    const teacherId = await getTeacher(uid);
    if (teacherId) {
      const config = await getRequestConfig();
      await django.patch(`/teachers/id/${teacherId}/`, convertedTeacherValues, config);
      store.dispatch({ type: types.UPDATE_CACHED_TEACHER_DATA, payload: teacherValues });
      return true;
    } else {
      console.error(`Failed to find the ID for teacher with fireBaseAuthId ${uid}`);
      return false;
    }
  } catch (err) {
    console.dir(err, {depth:null});
    console.error('There was a problem trying to update your information');

    return false;
  }
};
