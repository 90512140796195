import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import EmployerSignUpForm from '../../components/EmployerSignUpForm/EmployerSignUpForm';
import successLogo from '../../assets/success.png';
import { coordinatorEmail } from '../../state/actions/employerSignUp';

class EmployerSignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
   }

   handleClickOpen = () => {
    this.setState(false);
  }

  renderAlert = () => {
    const { employerSignup } = this.props;
    if (employerSignup.alertMessage) {
      return (
        <Alert variant="danger" dismissible>
          <strong>{employerSignup.alertMessage}</strong>
        </Alert>
      );
    }
    return null;
  };

  renderModal = () => {
    if (this.props.employerSignup.hasSignedUp) {
      // AB, TODO: Reset the state for employer signup so that if the user comes back to this page,
      // they aren't show the modal again that an email has been sent to the coordinator
   return (
     <Dialog
       open={true}
       onClose={this.handleClose}
     >
       <img
         src={successLogo}
         style={{
         width: 70,
         height: 70,
         paddingTop: 10,
         alignSelf: 'center',
          float: 'none',
          margin: 'auto',
          display: 'flex',
        justifyContent: 'center' }}
         alt="successLogo"
       />
       <p
         style={{
        alignSelf: 'center',
         float: 'none',
         margin: 'auto',
         display: 'flex',
         fontSize: 50,
       justifyContent: 'center' }}
       > Success
       </p>
       <hr />
       <div style={{ padding: 10 }}>
         Thanks for signing up!  We have sent an email to your district superintendent
         to verify that you are authorized to post jobs for the district.
         Once verified, you will get an email and be able to sign in.
       </div>
       <DialogActions>
         <Button onClick={() => this.props.history.replace('/login')} color="primary" autoFocus>
           Ok
         </Button>
       </DialogActions>
     </Dialog>
   );
    }
    return null;
  };

  render() {
    return (
      <div>
        {this.renderAlert()}
        {this.renderModal()}
        <EmployerSignUpForm />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employerSignup: state.employerSignup,
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(EmployerSignUpPage)
);
