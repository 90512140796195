import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomSelect from '../CustomSelect/CustomSelect';
import {
    timeOptions,
    gradeLevelOptions,
    licensureOptions,
} from '../CustomSelect/Options';

const essayQuestionsExtraHTML = (
  <div>
  <em>
    To add more questions to your pool, go to your{' '}
    <Link to="/employersettings">settings page</Link>
  </em>
  </div>
)

class JobPostingForm extends Component {
    state = {
        expirationDate: '',
    };

    renderTextInput = (formik, name, label, placeholder) => (
      <div>
        <label>{label}</label>
        <Form.Control
          {...formik.getFieldProps(name)}
          isInvalid={!!formik.errors[name] && formik.touched[name]}
          isValid={!formik.errors[name] && formik.touched[name]}
          placeholder={placeholder}
        />
      </div>
    );

    renderTextArea = (formik, name, label) => (
      <div>
        <label>{label}</label>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows="3"
            {...formik.getFieldProps(name)}
            isInvalid={formik.errors[name] && formik.touched[name]}
            isValid={!formik.errors[name] && formik.touched[name]}
          />
        </Form.Group>
      </div>
    );

    renderDateArea = (formik, name, label) => (
      <div>
        <label>{label}</label>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows="1"
            placeholder="mm/dd/yy"
            {...formik.getFieldProps(name)}
            isInvalid={formik.errors[name] && formik.touched[name]}
            isValid={!formik.errors[name] && formik.touched[name]}
          />
        </Form.Group>
      </div>
    );

    renderSelect = (formik, name, label, options, isMulti, placeholder, extraHTML) => {
      let defaultValue = [];
      if (name === 'selectedLicensure' || name === 'selectedGradeLevels' || name === 'selectedQuestions') {
          defaultValue = formik.values[name];
      } else {
          defaultValue = options.filter(option => option.value == formik.values[name]);
      }

      return (
        <div style={{ marginBottom: 20, width: 500 }}>
          <label>{label}</label>
          {extraHTML}
          <CustomSelect
            onChange={formik.setFieldValue}
            onBlur={formik.setFieldTouched}
            name={name}
            value={defaultValue}
            options={options}
            isMulti={isMulti}
            isInvalid={formik.errors[name] && formik.touched[name]}
            placeholder={placeholder}
          />
        </div>
      );
    };

    render() {
        const { props } = this.props;
        return (
          <div>
            {this.renderTextInput(props, 'title', 'Job title *', 'Enter job title')}
            {this.renderTextArea(props, 'description', 'Job description *')}
            {this.renderSelect(props, 'selectedLicensure', 'Desired areas of licensure *', licensureOptions, true)}
            {this.renderSelect(props, 'selectedPartOrFullTime', '(Optional) Is this job full or part-time?', timeOptions, false)}
            {this.renderSelect(props, 'selectedQuestions', '(Optional) Extra essay questions', this.props.questions, true, 'Select additional essay questions...', essayQuestionsExtraHTML)}
            {this.renderSelect(props, 'selectedGradeLevels', '(Optional) Grade levels (if applicable)', gradeLevelOptions, true, 'Grade level - select all that apply')}
            {this.renderDateArea(props, 'expirationDate', '(Optional) Posting expiration date')}
            {this.renderTextInput(props, 'externalLink', '(Optional) External Apply Link', 'https://www.applylink.com')}
          </div>
        );
    }
}

const mapStateToProps = state => ({
  questions: state.employer.questions,
});

export default connect(
  mapStateToProps,
  {}
)(JobPostingForm);