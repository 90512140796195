import React, { useState } from "react";
import { uploadBytesResumable } from "firebase/storage";
import PropTypes from "prop-types";

import { v4 as uuidv4 } from 'uuid';
const generateRandomFilename = () => uuidv4();

function extractExtension(filename) {
  let ext = /(?:\.([^.]+))?$/.exec(filename);
  if (ext != null && ext[0] != null) {
    return ext[0]
  } else {
    return ''
  }
}

const FileUploader = ({
  storageRef,
  onUploadStart,
  onProgress,
  onUploadSuccess,
  onUploadError,
  filename,
  metadata,
  randomizeFilename,
  hidden,
  as: CustomInput = "input",
  maxHeight,
  maxWidth,
  ...inputProps
}) => {
  const [uploadTask, setUploadTask] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    let fileName =
      typeof filename === "function"
        ? filename(file)
        : filename || (randomizeFilename ? generateRandomFilename() : file.name);

    // Ensure there is an extension in the filename
    if (!extractExtension(fileName)) {
        fileName += extractExtension(file.name);
    }

    const fileRef = storageRef.child(fileName);
    const uploadTaskInstance = uploadBytesResumable(fileRef, file, metadata);

    setUploadTask(uploadTaskInstance);
    onUploadStart && onUploadStart(file, uploadTaskInstance);

    uploadTaskInstance.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress && onProgress(progress, uploadTaskInstance);
      },
      (error) => {
        onUploadError && onUploadError(error);
      },
      async () => {
        try {
          onUploadSuccess && onUploadSuccess(uploadTaskInstance.snapshot.metadata.name, uploadTaskInstance);
        } catch (error) {
          onUploadError && onUploadError(error);
        }
      }
    );
  };

  const renderInput = () => {
    const inputStyle = hidden
      ? Object.assign({}, inputProps.style, {
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1
        })
      : inputProps.style;

    return (
      <CustomInput
        type="file"
        onChange={handleFileChange}
        {...inputProps}
        style={inputStyle}
      />
    )
  };

  return <div>{renderInput()}</div>;
};

FileUploader.propTypes = {
  storageRef: PropTypes.object.isRequired,
  onUploadStart: PropTypes.func,
  onProgress: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
  filename: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  metadata: PropTypes.object,
  randomizeFilename: PropTypes.bool,
  hidden: PropTypes.bool,
  as: PropTypes.elementType,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  formNoValidate: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  multiple: PropTypes.bool,
};

FileUploader.defaultProps = {
  randomizeFilename: false,
  hidden: false,
};

export default FileUploader;
