import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileUploader from '../FileUploader/FileUploader';
import { storage } from '../../firebase/Firebase';
import CustomButton from '../CustomButton/CustomButton';
import { trackUploadedDocument, handleDocumentUploadError } from '../../state/actions/newTeacherSetupForm';
import { renameDocument } from '../../state/actions/teacherSettings';
import { getTeacherRefLetters, getTeacherResumes, getTeacherTranscripts } from '../../state/actions/getTeachers';
import FileUploadProgress from '../FileUploadProgress/FileUploadProgress';
import store from '../../state';
import * as types from '../../state/actions/types';

class FileUploadButton extends Component {
  uploadStarted = () => {
    const { type } = this.props;
    if (type == 'transcripts') {
      store.dispatch({ type: types.UPLOAD_TEACHER_TRANSCRIPT_STARTED, payload: true });
    } else if (type == 'references') {
      store.dispatch({ type: types.UPLOAD_TEACHER_REFERENCE_LETTER_STARTED, payload: true });
    } else if (type == 'resumes') {
      store.dispatch({ type: types.UPLOAD_TEACHER_RESUME_STARTED, payload: true });
    }
  }
  updateProgress = (progress, task) => {
    const { type } = this.props;
    if (type == 'transcripts') {
      store.dispatch({ type: types.UPLOAD_TEACHER_TRANSCRIPT_PROGRESS, payload: progress });
    } else if (type == 'references') {
      store.dispatch({ type: types.UPLOAD_TEACHER_REFERENCE_LETTER_PROGRESS, payload: progress });
    } else if (type == 'resumes') {
      store.dispatch({ type: types.UPLOAD_TEACHER_RESUME_PROGRESS, payload: progress });
    }
  }
  
  render() {
    const {
      user,
      accept,
      labelText,
      type,
      folder
    } = this.props

    return (
      <div>
      <CustomButton>
        <label>
          {labelText}
          <FileUploader
            hidden
            accept={accept}
            name={labelText}
            randomizeFilename
            storageRef={storage.ref(folder)}
            onUploadStart={this.uploadStarted}
            onUploadError={error => this.props.handleDocumentUploadError(error, type)}
            onUploadSuccess={filename => {
              this.props.trackUploadedDocument(
                type,
                filename,
                `${type}/${user.uid}`,
                `Unnamed ${type} from ${new Date().toDateString()}`,
              )
              }
            }
            onProgress={this.updateProgress}
          />
        </label>
      </CustomButton>
      <FileUploadProgress type={this.props.type} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  teacherRefLetters: state.teacherRefLetters.uploads,
  teacherResumes: state.teacherResumes.uploads,
  teacherTranscripts: state.teacherTranscripts.uploads,
  teacher: state.teacher
});

export default connect(
  mapStateToProps,
  {
    trackUploadedDocument,
    handleDocumentUploadError,
    getTeacherRefLetters,
    getTeacherResumes,
    getTeacherTranscripts,
    renameDocument
  }
)(FileUploadButton);