import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renameDocument } from '../../state/actions/teacherSettings';
import { getTeacherRefLetters, getTeacherResumes, getTeacherTranscripts } from '../../state/actions/getTeachers';
import FileUploadButton from '../FileUploadButton/FileUploadButton';
import TeacherUploadRenameForm from '../TeacherUploadRenameForm/TeacherUploadRenameForm';

class TeacherUploads extends Component {

  fetchUserDocs() {
    const {
      user,
      teacherRefLetters,
      gettingTeacherRL,
      teacherResumes,
      gettingTeacherRes,
      teacherTranscripts,
      gettingTeacherTrans,
      gettingTeacherRLFailed,
      gettingTeacherResFailed,
      gettingTeacherTransFailed
    } = this.props;
    if (user != null) {
      if (teacherRefLetters == null && !gettingTeacherRL && !gettingTeacherRLFailed) {
        this.props.getTeacherRefLetters(user.uid);
      }
      if (teacherResumes == null && !gettingTeacherRes &&!gettingTeacherResFailed) {
        this.props.getTeacherResumes(user.uid);
      }
      if (teacherTranscripts == null && !gettingTeacherTrans && !gettingTeacherTransFailed) {
        this.props.getTeacherTranscripts(user.uid);
      }
    }
  }

  componentDidMount() {
    this.fetchUserDocs()
  }

  componentDidUpdate() {
    this.fetchUserDocs()
  }
 
  renderFileUploadButton = (labelText, type) => {
    const { user } = this.props;
    if (user) {
      return (
        <FileUploadButton
          accept=".pdf"
          labelText={labelText}
          type={type}
          folder={`${type}/${user.uid}`}
        />
      );
    }
    return null;
  };

  render() {
    const { teacherRefLetters, teacherResumes, teacherTranscripts } = this.props;
    if (teacherRefLetters && teacherResumes && teacherTranscripts) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {this.renderUploadErrorArea()}
          {this.renderFileUploadButton('Upload a reference letter (PDF only)', 'references')}
          <TeacherUploadRenameForm type='reference letter' />
          {this.renderFileUploadButton('Upload a resume (PDF only)', 'resumes')}
          <TeacherUploadRenameForm type='resume' />
          {this.renderFileUploadButton('Upload a transcript (PDF only)', 'transcripts')}
          <TeacherUploadRenameForm type='transcript' />
        </div>
      )
    } else {
      return (
        <div/>
      )
    }
  }

  renderUploadErrorArea = () => {
    const labelValues = {
      resumeUploadFailed: 'Resume',
      transcriptUploadFailed: 'Transcript',
      referenceLetterUploadFailed: 'Reference Letter',
    };

    const failedUploads = Object.keys(labelValues).filter(key => this.props[key] == true);
    let failedUploadsText = '';

    failedUploads.forEach(key => {
      failedUploadsText = failedUploadsText.concat(`, ${labelValues[key]}`);
      console.log(failedUploadsText);
    });
    failedUploadsText = failedUploadsText.slice(2);

    if (failedUploadsText !== '') {
      return (
        <div>
          <h3>Error with file upload(s): {failedUploadsText}</h3>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  user: state.auth.user, // user.uid is user id
  gettingTeacherRL: state.teacherRefLetters.gettingTeacherRL,
  gettingTeacherRLFailed: state.teacherRefLetters.gettingTeacherRLFailed,
  teacherRefLetters: state.teacherRefLetters.uploads,
  teacherResumes: state.teacherResumes.uploads,
  gettingTeacherRes: state.teacherResumes.gettingTeacherRes,
  gettingTeacherResFailed: state.teacherResumes.gettingTeacherResFailed,
  teacherTranscripts: state.teacherTranscripts.uploads,
  gettingTeacherTrans: state.teacherTranscripts.gettingTeacherTrans,
  gettingTeacherTransFailed: state.teacherTranscripts.gettingTeacherTransFailed,
  resumeUploadFailed: state.teacherResumes.uploadError,
  transcriptUploadFailed: state.teacherTranscripts.uploadError,
  referenceLetterUploadFailed: state.teacherRefLetters.uploadError,
});
  
export default connect(
  mapStateToProps,
  {
    getTeacherRefLetters,
    getTeacherResumes,
    getTeacherTranscripts,
    renameDocument
  }
)(TeacherUploads);