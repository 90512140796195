/* eslint-disable react/sort-comp */
// Similar to Jobs.js, but for the employer view
import React, { Component } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Formik } from 'formik';
import TimeAgo from 'react-timeago';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import { Alert, Form, ButtonToolbar } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import ApplicantListItem from '../ApplicantListItem/ApplicantListItem';
import CustomButton from '../CustomButton/CustomButton';
import CustomCard from '../CustomCard/CustomCard';
import {
  updateJobPostingStatus,
  updatePosting,
  cancelUpdatePosting,
} from '../../state/actions/postings';
import './Icon.css';
import styles from '../NewJobPosting/NewJobPosting.styles';
import JobPostingForm from '../../components/JobPostingForm/JobPostingForm';
import { validationSchema } from '../../components/JobPostingForm/FormikValues'

class Posting extends Component {
  state = {
    expanded: false,
    searchResults: [],
    stringSearch: '',
    hasDoneFirstSearch: false,
    isEditing: false,
    anchorEl: null,
    initialValues: {
      title: this.props.posting.title,
      description: this.props.posting.description,
      selectedLicensure: this.props.posting.selectedLicensure,
      selectedPartOrFullTime: this.props.posting.selectedPartOrFullTime,
      selectedQuestions: this.props.posting.selectedQuestions,
      selectedGradeLevels: this.props.posting.selectedGradeLevels,
      expirationDate: this.props.posting.expirationDate,
      externalLink: this.props.posting.externalLink,
    }
  };

  // AB: TODO: This works, but it seems like there should be a better way than
  // putting initialValues in component state.
  componentDidMount() {
    this.state.initialValues = {
      title: this.props.posting.title,
      description: this.props.posting.description,
      selectedLicensure: this.props.posting.selectedLicensure,
      selectedPartOrFullTime: this.props.posting.selectedPartOrFullTime,
      selectedQuestions: this.props.posting.selectedQuestions,
      selectedGradeLevels: this.props.posting.selectedGradeLevels,
      expirationDate: this.props.posting.expirationDate,
      externalLink: this.props.posting.externalLink,
    };
  }

  componentDidUpdate() {
    this.state.initialValues = {
      title: this.props.posting.title,
      description: this.props.posting.description,
      selectedLicensure: this.props.posting.selectedLicensure,
      selectedPartOrFullTime: this.props.posting.selectedPartOrFullTime,
      selectedQuestions: this.props.posting.selectedQuestions,
      selectedGradeLevels: this.props.posting.selectedGradeLevels,
      expirationDate: this.props.posting.expirationDate,
      externalLink: this.props.posting.externalLink,
    };
  }

  onApplicantClick(resumeLink) {
    const win = window.open(resumeLink, '_blank');
    win.focus();
  }

  onSearchTextChange = e => {
    this.setState({ stringSearch: e.target.value });
    this.updateSearchResults(e.target.value);
  };

  getTimeAgo = () => {
    if (this.props.posting.isClosed && this.props.posting.dateClosed) {
      return this.props.posting.dateClosed;
    }
    return this.props.posting.datePosted;
  };

  updateSearchResults = searchString => {
    this.setState({ hasDoneFirstSearch: true });

    // If no inputs are good, searchResults contains all applicants.
    let currentResults = this.props.applicants;
    // TODO: description matching is wonky, can we fix this?
    // TODO: Search in more fields (district name, etc)
    if (searchString !== '') {
      const options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['firstName', 'lastName'],
      };
      const fuse = new Fuse(currentResults, options);
      currentResults = fuse.search(searchString);
    }

    // update the final search results
    this.setState({ searchResults: currentResults });
  };

  renderApplicant(applicant, jobId) {
    // TODO: optimize to only send the relevant resume link
    const { proceedStatus } = applicant;
    const { firstName, lastName, id } = applicant;
    const nameString = `${firstName} ${lastName}`;
    const key = `${jobId}${applicant.id}`
    const linkString = `/application/${jobId}/${applicant.id}`;
    return (
      <ApplicantListItem
        key={key}
        nameString={nameString}
        linkTo={linkString}
        proceedStatus={proceedStatus}
      />
    );
  }

  renderApplicantResults = () => {
    const { posting } = this.props;
    if (!this.state.hasDoneFirstSearch) {
      return this.props.applicants.map(applicant => this.renderApplicant(applicant, posting.id));
    }
    if (this.state.searchResults.length === 0) {
      return <div>No search results found!</div>;
    }
    return this.state.searchResults.map(applicant => this.renderApplicant(applicant, posting.id));
  };

  renderSearch = () => (
    <div>
      <form style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          id="standard-name"
          label="Search"
          margin="normal"
          helperText="Search for applicants"
          value={this.state.stringSearch}
          onChange={this.onSearchTextChange}
          style={{ marginLeft: 10, marginRight: 10 }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
      </form>
    </div>
  );

  renderMenu = () => (
    <Menu
      id="long-menu"
      anchorEl={this.state.anchorEl}
      keepMounted
      open={!!this.state.anchorEl}
      onClose={() => this.setState({ anchorEl: null })}
    >
      <MenuItem
        onClick={() => {
          this.setState({ isEditing: true });
          this.setState({ anchorEl: null });
        }}
      >
        Edit the posting
      </MenuItem>
      <MenuItem
        onClick={() => {
          this.props.updateJobPostingStatus(this.props.posting, !this.props.posting.isClosed);
          this.setState({ anchorEl: null });
        }}
      >
        {`${this.props.posting.isClosed ? 'Open' : 'Close'} this job posting`}
      </MenuItem>
    </Menu>
  );

  renderAlert = () => {
    if (this.props.backendError != '') {
      return (
        <Alert variant="danger">
          Sorry, there was an error updating your job posting. Please check your entries and try again.
        </Alert>
      );
    }
    return null;
  };

  onSubmitEditJobClick = async (jobValues) => {
    const editJobSuccessful = await this.props.updatePosting(this.props.posting, jobValues);
    return editJobSuccessful;
  }

  renderEditForm = () =>
     (
       <div style={styles.formCenterStyle}>
        {this.renderAlert()}
        <Formik
          onSubmit={async (values, actions) => {
            const editJobSuccessful = await this.onSubmitEditJobClick(values);
            if(editJobSuccessful) {
              this.setState({ isEditing: false });
            }
            actions.setSubmitting(false);
          }}
          initialValues={this.state.initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <JobPostingForm props={formik} />
              <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                {this.renderCardEditButtons(formik)}
              </ButtonToolbar>
            </Form>
          )}
        </Formik>
       </div>
    )

  // to view job posting
  renderUpdatePost = () => {
    const { isEditing } = this.state;
    if (isEditing) {
      return (
        <Dialog
          open={this.state.isEditing}
          onClose={() => this.handleClose}
        >
          <div style={{ padding: 10 }}>
            {this.renderEditForm()}
          </div>
        </Dialog>
      );
    }
  };

  renderPostHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {this.renderTitle()}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography color="textSecondary">
          {this.props.posting.isClosed ? 'Closed' : 'Posted'} <TimeAgo
            date={this.getTimeAgo()}
          />
        </Typography>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
          size="large">
          <MoreVertIcon />
        </IconButton>
        {this.renderMenu()}
      </div>
    </div>
  );

  renderTitle = () => {
    const { isEditing } = this.state;
    return (
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
      >
        {this.props.posting.title}
      </Typography>
    );
  };

  renderDescription = () => {
    const { isEditing } = this.state;
    return (
      <Typography>
        {this.props.posting.description}
      </Typography>
    );
  };

  renderCardEditButtons = (formik) => {
    const { isEditing } = this.state;
    const { posting } = this.props;
    
    if (isEditing) {
      return (
        <CardActions>
          <CustomButton
            variant="success-contained"
            type="submit"
            isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          >
            Save
          </CustomButton>
          <CustomButton
            onClick={async () => {
              this.setState({ isEditing: false });
              await this.props.cancelUpdatePosting();
            }}
          >
            Cancel
          </CustomButton>
        </CardActions>
      );
    }

    return null;
  };

  render() {
    const { applicants } = this.props;
    const { expanded } = this.state;

    const canExpand = applicants.length > 0;
    let btnText = canExpand
      ? `View applicants (${applicants.length})`
      : 'No applicants';
    if (expanded) {
      btnText = 'Hide applicants';
    }
    const btnColor = canExpand ? 'primary' : 'secondary';

    return (
      <CustomCard>
        {this.renderPostHeader()}
        {this.renderDescription()}
        {this.renderUpdatePost()}
        <CardActions>
          <CustomButton
            onClick={() => this.setState({ expanded: !expanded })}
            isDisabled={!canExpand}
            variant={btnColor}
          >
            {btnText}
          </CustomButton>
        </CardActions>
        <Collapse in={this.state.expanded}>
          {this.renderSearch()}
          <CardContent>
            <List>{this.renderApplicantResults()}</List>
          </CardContent>
        </Collapse>
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  postings: state.employer.postings,
  questions: state.employer.questions,
  backendError: state.employer.backendError,
});

export default connect(
  mapStateToProps,
  {
    updatePosting,
    updateJobPostingStatus,
    cancelUpdatePosting,
  }
)(Posting);
