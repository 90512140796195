import django from '../../apis/django';
import * as types from './types';
import store from '..';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const getAllTeachers = () => async dispatch => {
  try {
      const config = await getRequestConfig();
      const response = await django.get(`/teachers/`, config);
      return response.data;
  } catch (error) {
    console.error(error);
    console.error('getAllTeachers: There was a problem getting the teachers');
    return null;
  }
};


export const getJobs = () => async () => {
  var jobs = [];
  try {
    const config = await getRequestConfig();
    const response = await django.get('/jobs/', config);
    jobs = response.data
    store.dispatch({ type: types.GET_JOBS_LIST_SUCCESS, payload: jobs });
  } catch (err) {
    console.error(err);
    console.error('There was a problem getting jobs');
  }
  return jobs;
};
