import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { Form, FormCheck } from 'react-bootstrap';
import { Formik } from 'formik';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { renameDocument, editSetting } from '../../state/actions/teacherSettings';
import { logoutUser } from '../../state/actions/auth';
import { getTeacherRefLetters, getTeacherResumes, getTeacherTranscripts } from '../../state/actions/getTeachers';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomSnackBar from '../../components/CustomSnackBar/CustomSnackBar';
import styles from './TeacherSettings.styles';
import PageLayout from '../PageLayout/PageLayout';
import Theme from '../../theme/Theme';
import { schema, initialValues } from '../TeacherSignUpPage/TeacherSignUpPageFormikValues';
import TeacherInformationForm from '../../components/TeacherInformationForm/TeacherInformationForm';
import CustomCard from '../../components/CustomCard/CustomCard';
import DocListItem from '../../components/DocListItem/DocListItem';
import TeacherUploads from '../../components/TeacherUploads/TeacherUploads';


// todo: put these files in a folder where the name of the folder is the user id
// on complete, take the file name and add it to the user's resume docs
// TODO: Change resumeName to just name everywhere
// TODO: add an alert to let the user know their file was uploaded
// TODO: Make the user enter a filename or use the one from the file
// TODO: Move Document rendering into a sperate component
// TODO: make it such that each document does not have their own modal
class TeacherSettings extends Component {
  state = {
    newDocName: '',
    // isPaused: false,
    currentSetting: 'GENERAL',
    isSnackBarOpen: false,
    snackBarMessage: '',
    isOpen: false,
    expandRL: false,
    expandRes: false,
    expandTrans: false
  };

  componentDidMount() {
    if (this.props.user == null && this.props.isLoggingIn == false) {
      this.props.history.push('/');
    }
    if (this.props.user != null) {
      this.props.getTeacherRefLetters(this.props.user.uid);
      this.props.getTeacherResumes(this.props.user.uid);
      this.props.getTeacherTranscripts(this.props.user.uid);
    }
  }

  renderDocument(id, docId, name, url, type) {
    // TODO: optimize to only send the relevant resume link
    return (
      // <a href={url}>{name}</a>
      <DocListItem
        key={id}
        docId={docId}
        name={name}
        link={url}
        type={type}
      />
    );
  }

  renderDocuments = (Documents, type) => Documents.map(doc => this.renderDocument(doc[0], doc[1], doc[2], doc[3], type));

  renderReferenceLetters = () => {
    const { teacherRefLetters } = this.props;
    const { expandRL } = this.state;
    let canExpand;
    if (teacherRefLetters) {
      canExpand = teacherRefLetters.length > 0;
    }
    let btnText = canExpand
      ? `Amount (${teacherRefLetters.length})`
      : 'No Reference Letters Uploaded';
    if (expandRL) {
      btnText = 'Hide Reference Letters';
    }

    const btnColor = canExpand ? 'primary' : 'secondary';

    return (
      <div style={styles.padLeftStyle}>
        <CustomCard>
          <h5>Reference Letters</h5>
          <CardActions>
            <CustomButton
              onClick={() => this.setState({ expandRL: !expandRL })}
              isDisabled={!canExpand}
              variant={btnColor}
            >
              {btnText}
            </CustomButton>
          </CardActions>
          <Collapse in={this.state.expandRL}>
            <CardContent>
              <List>{this.renderDocuments(teacherRefLetters, 'reference letter')}</List>
            </CardContent>
          </Collapse>
        </CustomCard>
      </div>
    );
  };

  renderResumes = () => {
    const { teacherResumes } = this.props;
    const { expandRes } = this.state;
    let canExpand;
    if (teacherResumes) {
      canExpand = teacherResumes.length > 0;
    }
    let btnText = canExpand
      ? `Amount (${teacherResumes.length})`
      : 'No Resumes Uploaded';
    if (expandRes) {
      btnText = 'Hide Resumes';
    }
    const btnColor = canExpand ? 'primary' : 'secondary';

    return (
      <div style={styles.padLeftStyle}>
        <CustomCard>
          <h5>Resumes</h5>
          <CardActions>
            <CustomButton
              onClick={() => this.setState({ expandRes: !expandRes })}
              isDisabled={!canExpand}
              variant={btnColor}
            >
              {btnText}
            </CustomButton>
          </CardActions>
          <Collapse in={this.state.expandRes}>
            <CardContent>
              <List>{this.renderDocuments(teacherResumes, 'resume')}</List>
            </CardContent>
          </Collapse>
        </CustomCard>
      </div>
    );
  };

  renderTranscripts = () => {
    const { teacherTranscripts } = this.props;
    const { expandTrans } = this.state;
    let canExpand;
    if (teacherTranscripts) {
      canExpand = teacherTranscripts.length > 0;
    }
    let btnText = canExpand
      ? `Amount (${teacherTranscripts.length})`
      : 'No Transcripts Uploaded';
    if (expandTrans) {
      btnText = 'Hide Transcripts';
    }

    const btnColor = canExpand ? 'primary' : 'secondary';

    return (
      <div style={styles.padLeftStyle}>
        <CustomCard>
          <h5>Transcripts</h5>
          <CardActions>
            <CustomButton
              onClick={() => this.setState({ expandTrans: !expandTrans })}
              isDisabled={!canExpand}
              variant={btnColor}
            >
              {btnText}
            </CustomButton>
          </CardActions>
          <Collapse in={this.state.expandTrans}>
            <CardContent>
              <List>{this.renderDocuments(teacherTranscripts, 'transcript')}</List>
            </CardContent>
          </Collapse>
        </CustomCard>
      </div>
    );
  };

  renderGeneral = () => {
    const { teacherRefLetters, teacherResumes, teacherTranscripts } = this.props;
    if (teacherRefLetters && teacherResumes && teacherTranscripts) {
      return (
        <div>
          <TeacherUploads/>
          {this.renderReferenceLetters()}
          {this.renderResumes()}
          {this.renderTranscripts()}
        </div>
      )
    } else {
      return (
        <div>
          <TeacherUploads/>
        </div>
      )
    }
  };

  renderEdit = () => {
    // AB: 8/6/22. userData was being used for initialValues in Formik
    // but that's invalid. You have to pass in legitimate initial values
    // for all of the form fields. userData is from state.auth.userData,
    // and has nothing to do with this form.
    // Passing in the actual initialValues resolved the error: 
    // A component is changing an uncontrolled input to be controlled.
    // However, evidently it is intended to duplicate user data information
    // in the teacher record (TODO: this needs to be fixed). So, instead of
    // replacing initialValues with initialValues, I'm instead merging 
    // userData in. Apparently what this does (I guess?) is creates hidden
    // inputs with the userData, for that to be saved in the Teacher record
    // on Firebase. This is amazingly bad.
    const { userData } = this.props;
    // TODO: MOVE THIS TO ITS OWN COMPONENT, IT IS BEING RE-USED
    return (
      <div style={styles.settingsPageContainer}>
        <Formik
          onSubmit={async values => {
            const result = await editSetting(values);
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: result ? 'Success!' : 'Failed to update.',
            });
          }}
          initialValues={{...initialValues, ...userData}}
          // darren@synsesolutions.com 2023-03-08
          // quick switch to turn off validation to determine 
          // if validation rules are causing submit errors
          // validator={ () => {} }
          validationSchema={schema}
        >
          {formik => (
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* This is to show a list of error messages above the form, enable when debugging */}
              {/* {Object.values(formik.errors).map(error => (
                  <div key={error}>{error}</div>
                ))} */}
              <TeacherInformationForm props={formik} />
              {this.renderCheckBox(formik, 'hasReadPolicy', 'Yes!  I have read the privacy policy.', true)}
              {this.renderCheckBox(formik, 'hasAgreedToTerms', 'Yes!  I agree to the terms and conditions.', true)}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 25,
                marginBottom: 10,
              }}
              >
                <label style={{ fontWeight: 'bold' }}>ARteachers.org wants to match great teachers with great schools.</label>
                <label style={{ fontWeight: 'bold' }}>Check the boxes below to let schools find you.</label>
              </div>
              {this.renderCheckBox(formik, 'shouldShareData', 'Yes! Please share my information with other districts looking for great teachers like me.', false)}
              {this.renderCheckBox(
                formik,
                'longTermSubstitute',
                'Yes!  I am interested in long-term substitute opportunities. (On selecting this, you are agreeing to share your data with districts. Please select the checkbox above this one if you have already not selected it.)', false)}
              <CustomButton
                type="submit"
                // isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              >
                Submit
              </CustomButton>
            </Form>
          )}
        </Formik>
        {/* As of now, this does nothing.  It is just for looks
        <CustomSwitch
          label={this.state.isPaused ? 'Your profile is paused' : 'Your profile is active'}
          onChange={event => this.setState({ isPaused: event.target.checked })}
          checked={this.state.isPaused}
        /> */}
      </div>
    );
  };

  renderSubTeacherNotify = () => {
    if (this.state.isOpen === true) {
      return (
        <Dialog
          open={() => this.handleClickOpen}
          onClose={() => this.handleClose}
        >
          <div style={{ padding: 10 }}>
            On selecting this, you are agreeing to share your data with districts. Please select the checkbox
            above this if you have already not selected it.
          </div>
          <DialogActions>
            <Button onClick={() => this.closeModal()} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  }

  renderModal = (props, name) => {
    if (name === 'longTermSubstitute') {
      this.setState({ isOpen: true });
    }
  };

  renderCheckBox = (formik, name, label, useIsValid=true) => (
    <div
      style={{
        marginBottom: 20,
        width: 500,
      }}
    >
      <FormCheck>
        <FormCheck.Input
          name={name}
          {...formik.getFieldProps(name)}
          isInvalid={useIsValid && formik.errors[name] && formik.touched[name]}
          isValid={useIsValid && !formik.errors[name] && formik.touched[name]}
          value={formik.values[name]}
          defaultChecked={formik.values[name]}
        />
        <FormCheck.Label>{label}</FormCheck.Label>
      </FormCheck>
    </div>
  );

  renderSettingsPage = () => {
    switch (this.state.currentSetting) {
      case 'GENERAL': {
        return this.renderGeneral();
      }
      case 'EDIT': {
        return this.renderEdit();
      }
      default: {
        return null;
      }
    }
  };

  render() {
    const { user } = this.props;

    if (!user) {
      return (
        <div
          style={styles.loadingScreenLayout}
        >
          <h3>Thank you for visiting ARTeachers.org!</h3>;
        </div>
      );
    }
    // TODO: RE-FETCH THE STATE AFTER UPLOADING? Or just save into the state.
    // User should be able to select these after creating them.
    const { currentSetting } = this.state;
    return (
      <PageLayout>
        <CustomSnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() => this.setState({ isSnackBarOpen: false })}
        />
        <h2 style={{
          display: 'inline-block',
          align: 'center',
        }}
        >Settings
        </h2>
        <div style={{
          display: 'inline-block',
          align: 'center',
        }}
        >
          <b
            onClick={() => this.setState({ currentSetting: 'GENERAL' })}
            style={{
              margin: 90,
              fontWeight: currentSetting === 'GENERAL' ? 'bold' : 'normal',
              color: currentSetting === 'GENERAL' ? Theme.ThemePrimary : 'black',
              cursor: 'pointer',
            }}
          >
            Documents
          </b>
          <b
            onClick={() => this.setState({ currentSetting: 'EDIT' })}
            style={{
              margin: 70,
              fontWeight: currentSetting === 'EDIT' ? 'bold' : 'normal',
              color: currentSetting === 'EDIT' ? Theme.ThemePrimary : 'black',
              cursor: 'pointer',
            }}
          >
            Edit My Profile
          </b>
        </div>
        <div style={styles.settingLayout}>
          {this.renderSettingsPage()}
          {this.renderSubTeacherNotify()}
        </div>
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  state,
  user: state.auth.user, // user.uid is user id
  teacherRefLetters: state.teacherRefLetters.uploads,
  teacherResumes: state.teacherResumes.uploads,
  teacherTranscripts: state.teacherTranscripts.uploads,
  userData: state.auth.userData,
  isLoggingIn: state.auth.isLoggingIn
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getTeacherRefLetters,
      getTeacherResumes,
      getTeacherTranscripts,
      logoutUser,
      renameDocument,
    },
  )(TeacherSettings),
);
