import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import Iframe from 'react-iframe';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomModal from '../CustomModal/CustomModal';
import CustomButton from '../CustomButton/CustomButton';
import TeacherUploadRenameForm from '../TeacherUploadRenameForm/TeacherUploadRenameForm';
import { deleteDocument } from '../../state/actions/documents';
import store from '../../state';
import * as types from '../../state/actions/types';

class DocListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isViewDocumentModalOpen: false,
            isRenameDocumentModalOpen: false,
            isDeleteDocumentModalOpen: false,
            anchorEl: null
        };
    }

    openViewDocumentModal = () => {
      this.setState({ isViewDocumentModalOpen: true });
    };

    onCloseViewDocumentModalClick = () => {
      this.setState({ isViewDocumentModalOpen: false });
    }

    renderViewDocumentModalContent = () => (
      <div key={this.props.link}>
        <div style={{ textAlign: 'right' }}>
          <CustomButton
            onClick={this.onCloseViewDocumentModalClick}
            customStyle={{ width: 30, height: 30 }}
          >
            X
          </CustomButton>
        </div>
        <Iframe
          url={this.props.link}
          width="600px"
          height="700px"
          id={this.props.link}
          display="initial"
          position="relative"
          allowFullScreen
        />
      </div>
    )

    openRenameDocumentModal = () => {
        this.setState({ isRenameDocumentModalOpen: true });
    };

    openDeleteDocumentModal = () => {
      this.setState({ isDeleteDocumentModalOpen: true });
    };

    renameSelectorName = () => {
      var type = this.props.type;
      if (type == 'transcript') {
        return types.TEACHER_TRANSCRIPT_DOC_RENAME_MODAL_CLOSED
      } else if (type == 'reference letter') {
        return types.TEACHER_REFERENCE_LETTER_DOC_RENAME_MODAL_CLOSED
      } else if (type == 'resume') {
        return types.TEACHER_RESUME_DOC_RENAME_MODAL_CLOSED
      }
    }

    deleteSelectorName = () => {
      var type = this.props.type;
      if (type == 'transcript') {
        return types.TEACHER_TRANSCRIPT_DOC_DELETE_MODAL_CLOSED
      } else if (type == 'reference letter') {
        return types.TEACHER_REFERENCE_LETTER_DOC_DELETE_MODAL_CLOSED
      } else if (type == 'resume') {
        return types.TEACHER_RESUME_DOC_DELETE_MODAL_CLOSED
      }
    }

    onCloseRenameDocumentModalClick = () => {
      // Dispatch an action that will clear renameDone
      store.dispatch({ type: this.renameSelectorName() });
      this.setState({ isRenameDocumentModalOpen: false });
    }

    onCloseDeleteDocumentModalClick = () => {
      // Dispatch an action that will clear deleteDone
      store.dispatch({ type: this.deleteSelectorName() });
      this.setState({ isDeleteDocumentModalOpen: false });
    }

    renderRenameDocumentModalContent = () => (
      <div key={'rename' + this.props.link}>
        <div style={{ textAlign: 'right' }}>
          <CustomButton
            onClick={this.onCloseRenameDocumentModalClick}
            customStyle={{ width: 30, height: 30 }}
          >
            X
          </CustomButton>
        </div>
        <TeacherUploadRenameForm inModal="true" type={this.props.type} name={this.props.name} docId={this.props.docId}/>
      </div>
    )

    handleDeleteDocument = async (docId) => {
      // Here is where we need to do the actual API calls to delete
      // Then based on their success we close the modal, or on their failure
      // we don't close but rather an error message appears triggered by a 
      // state change.
      const result = await deleteDocument(this.props.type, docId)
      if (result == 'OK') {
        this.onCloseDeleteDocumentModalClick()
      }
    }

    renderDeleteModalErrorMessage = () => {
      var type = this.props.type;
      return (
        <div>
          {((type == 'transcript' && this.props.transcriptDeleteFailed) ||
              (type == 'reference letter' && this.props.referenceLetterDeleteFailed) ||
              (type == 'resume' && this.props.resumeDeleteFailed)) &&
              <h4>Error - Document delete failed. Please try again.</h4>
          }
        </div>
      )
    }

    renderDeleteDocumentModalContent = () => (
      <div key={'delete' + this.props.link}>
        {this.renderDeleteModalErrorMessage()}
        <div>
          Are you sure you want to delete this document?
        </div>
        <div>
          <CustomButton
              onClick={() => {this.handleDeleteDocument(this.props.docId)}}
            >
              Yes, delete it
            </CustomButton>
            <CustomButton
              onClick={this.onCloseDeleteDocumentModalClick}
            >
              No, don't delete it
            </CustomButton>
          </div>
      </div>
    )

    renderMenu = () => (
      <Menu
        id="long-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={!!this.state.anchorEl}
        onClose={() => this.setState({ anchorEl: null })}
      >
        <MenuItem
          onClick={() => {
            this.openViewDocumentModal();
            this.setState({ anchorEl: null });
          }}
        >
          View document
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.openRenameDocumentModal();
            this.setState({ anchorEl: null });
          }}
        >
          Rename document
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.openDeleteDocumentModal();
            this.setState({ anchorEl: null });
          }}
        >
          Delete document
        </MenuItem>
      </Menu>
    );

    render() {
        return (
          <div>
            <CustomModal
              isOpen={this.state.isViewDocumentModalOpen}
              onClose={() => this.setState({
                        isViewDocumentModalOpen: false
                    })}
              modalContent={this.renderViewDocumentModalContent()}
            />
            <CustomModal
              isOpen={this.state.isRenameDocumentModalOpen}
              onClose={() => {
                store.dispatch({ type: this.renameSelectorName() });
                this.setState({
                        isRenameDocumentModalOpen: false
                    }
                )}}
              modalContent={this.renderRenameDocumentModalContent()}
            />
            <CustomModal
              isOpen={this.state.isDeleteDocumentModalOpen}
              onClose={() => {
                this.setState({
                        isDeleteDocumentModalOpen: false
                    }
                )}}
              modalContent={this.renderDeleteDocumentModalContent()}
            />
            <ListGroup.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
              {this.props.name}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                size="large">
                <MoreVertIcon />
              </IconButton>
              {this.renderMenu()}
            </ListGroup.Item>
          </div>
        );
    }
}

const mapStateToProps = state => ({
  resumeDeleteFailed: state.teacherResumes.deleteFailed,
  transcriptDeleteFailed: state.teacherTranscripts.deleteFailed,
  referenceLetterDeleteFailed: state.teacherRefLetters.deleteFailed,
});

export default connect(
  mapStateToProps
)(DocListItem);