import React from 'react';

function EmployerSignUpSuccessPage() {
  return (
    <b>
      You have succesfully signed up. Ask your superintendent to check their
      email for a verification link that will allow you to log in.
    </b>
);
}

export default EmployerSignUpSuccessPage;
