import * as types from '../actions/types';

// Student union, change to null?
const INITIAL_STATE = {
  isSigningUp: false,
  hasSignedUp: false,
  alertMessage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_EMPLOYER:
      return {
        ...INITIAL_STATE,
        isSigningUp: true,
      };
    case types.CREATE_EMPLOYER_SUCCESS:
      return {
        ...INITIAL_STATE,
        isSigningUp: false,
        hasSignedUp: true,
      };
    case types.CREATE_EMPLOYER_FAIL:
      return { ...INITIAL_STATE, alertMessage: action.payload };
    default:
      return state;
  }
};
