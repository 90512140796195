import { storage, auth } from '../../firebase/Firebase';
import django from '../../apis/django';
import { getTeacherByUID } from './auth.js';
import { getTeacher } from './getTeachers.js';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

import * as types from './types';
import store from '..';

export const setupTeacher = (
  teacherValues
) => async dispatch => {
  try {
    const { uid } = auth.currentUser;

    dispatch({ type: types.SETUP_TEACHER_SUBMIT, payload: teacherValues });
    dispatch({ type: types.SETUP_TEACHER });

    // Based on the logic behind the field dependencies on the Teacher Settings page,
    // clear out fields that should have no value.
    if (!teacherValues.hasTeachingLicense) {
      // The teacher does not have a license
      teacherValues.teachingLicenseType = [];
      teacherValues.hasArTeachingLicense = false;
      teacherValues.selectedLicensure = [];
    } else {
      // The teacher has a license
      teacherValues.towardsTeachingLicense = false;
      teacherValues.willToWorkTowardsLicense = false;
      teacherValues.expectedLicenseDate = "";
    }
    if (teacherValues.hasArTeachingLicense) {
      // Teacher is licensed in AR
      teacherValues.licensureAndState = "";
    }
    if (teacherValues.towardsTeachingLicense) {
      // Teacher is already working towards license
      teacherValues.willToWorkTowardsLicense = false;
    } else {
      // Teacher is not working towards license
      teacherValues.expectedLicenseDate = "";
      teacherValues.teachingLicenseWorkingTowards = "";
    }
    if (!teacherValues.violationOfLaw) {
      teacherValues.violationOfLawExplain = "";
    }
    if (!teacherValues.revokedCertificate) {
      teacherValues.revokedCertificateExplain = "";
    }
    if (!teacherValues.sexuallyAbusedChild) {
      teacherValues.sexuallyAbusedChildExplain = "";
    }
    if (!teacherValues.substantiatedChildAbuseCharge) {
      teacherValues.substantiatedChildAbuseChargeExplain = "";
    }
    if (!teacherValues.hasBeenFired) {
      teacherValues.hasBeenFiredExplain = "";
    }

    let convertedTeacherValues = teacherValues;

    // We have to stringify selectedLicensure, teachingLicenseType, and
    // teachingLicenseWorkingTowards to save them in the backend database as
    // strings. django will complain if we try to send it a Javascript object.
    if (teacherValues.selectedLicensure) {
      convertedTeacherValues.selectedLicensure = JSON.stringify(teacherValues.selectedLicensure)
    }
    if (teacherValues.teachingLicenseType) {
      convertedTeacherValues.teachingLicenseType = JSON.stringify(teacherValues.teachingLicenseType)
    }
    if (teacherValues.teachingLicenseWorkingTowards) {
      convertedTeacherValues.teachingLicenseWorkingTowards = JSON.stringify(teacherValues.teachingLicenseWorkingTowards)
    }

    const teacherUpdate = {
      ...convertedTeacherValues,
      hasCompletedSignup: true
    };

    const teacherId = await getTeacher(uid);
    if (teacherId) {
      const config = await getRequestConfig();
      await django.patch(`/teachers/id/${teacherId}/`, teacherUpdate, config);
      store.dispatch({ type: types.UPDATE_CACHED_TEACHER_DATA, payload: teacherUpdate });
      store.dispatch({ type: types.SETUP_TEACHER_SUCCESS });
      return true;
    } else {
      console.error(`Failed to find the ID for teacher with fireBaseAuthId ${uid}`);
      return false;
    }
  } catch (err) {
    console.dir(err, {depth: null});
    console.error('There was a problem setting up the teacher');
  }
};

// Saves the filename from storage into the user's database
export const trackUploadedDocument = (
  folder,
  fileName,
  path,
  documentName
) => async dispatch => {
  // Indicate in state that the upload has stopped
  if (folder == 'transcripts') {
    dispatch({ type: types.UPLOAD_TEACHER_TRANSCRIPT_STOPPED, payload: true });
  } else if (folder == 'references') {
    dispatch({ type: types.UPLOAD_TEACHER_REFERENCE_LETTER_STOPPED, payload: true });
  } else if (folder == 'resumes') {
    dispatch({ type: types.UPLOAD_TEACHER_RESUME_STOPPED, payload: true });
  }
  try {
    const { uid } = auth.currentUser;
    const teacher = await getTeacherByUID(uid);
    if (!teacher || !teacher?.id) {
      console.error("Unable to find teacher for attachment upload.")
      return
    }
    // Get the Firebase download URL
    const downloadURL = await storage
      .ref(path)
      .child(fileName)
      .getDownloadURL()
    const fileWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    const attachment = {
      docId: fileWithoutExtension,
      fileName: documentName,
      teacher: teacher.id
    }
    const config = await getRequestConfig();
    const response = await django.post(`/attachments/${folder}/`, attachment, config);

    // This has to match the expected payload format defined in the TeacherRef[Document]Reducer
    // and the way we write to state when loading attachments, in getTeachers.js
    // 0: id
    // 1: docid (firebase)
    // 2: filename
    // 3: downloadURL
    const fileInfo = [
      response.data.id,
      fileWithoutExtension,
      response.data.fileName,
      downloadURL,
    ]
    // AB TODO: Do this (dispatching the right action based on the folder) a cleaner way
    if (folder == 'transcripts') {
      // Transcripts
      dispatch({ type: types.UPLOAD_TEACHER_TRANSCRIPT_SUCCESS, payload: { fileInfo, docId: fileWithoutExtension, folder } });
    } else if (folder == 'references') {
      // References
      dispatch({ type: types.UPLOAD_TEACHER_REFERENCE_LETTER_SUCCESS, payload: { fileInfo, docId: fileWithoutExtension, folder } });
    } else if (folder == 'resumes') {
      // Resumes
      dispatch({ type: types.UPLOAD_TEACHER_RESUME_SUCCESS, payload: { fileInfo, docId: fileWithoutExtension, folder } });
    }

  } catch (err) {
    console.dir(err, {depth:null});
    console.error('There was a problem trying to track the uploaded document.');
  }
};

export const handleDocumentUploadError = (error, folder) => dispatch => {
  // AB TODO: Do this (dispatching the right action based on the folder) a cleaner way
  if (folder == 'transcripts') {
    // Transcripts
    dispatch({ type: types.UPLOAD_TEACHER_TRANSCRIPT_FAIL });
  } else if (folder == 'references') {
    // References
    dispatch({ type: types.UPLOAD_TEACHER_REFERENCE_LETTER_FAIL });
  } else if (folder == 'resumes') {
    // Resumes
    dispatch({ type: types.UPLOAD_TEACHER_RESUME_FAIL });
  }
}