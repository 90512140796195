import * as types from '../actions/types';

const INITIAL_STATE = {
    teacher: null,
    gettingTeacher: false,
    gettingTeacherJobsAppliedFor: false,
    gettingTeacherJobsAppliedForFailed: false,
    teacherJobsAppliedFor: null,
    teacherJobsApplyingFor: [],
    teacherJobsAppliedForDetailed: null,
    gettingTeacherJobsAppliedForDetailed: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_TEACHER:
            return { ...state, gettingTeacher: true };
        case types.GET_TEACHER_SUCCESS:
            // selectedLicensure, teachingLicenseWorkingTowards, and teachingLicenseType
            // are all JavaScript objects. They have to be JSON.stringify()'d before being
            // sent to the backend. When they come back from the backend, they have to be
            // JSON.parse()'d back into JavaScript objects.
            return { ...state, teacher: { ...action.payload, selectedLicensure: action.payload.selectedLicensure ? JSON.parse(action.payload.selectedLicensure): [], teachingLicenseWorkingTowards: action.payload.teachingLicenseWorkingTowards ? JSON.parse(action.payload.teachingLicenseWorkingTowards) : [], teachingLicenseType: action.payload.teachingLicenseType ? JSON.parse(action.payload.teachingLicenseType) : [] }, gettingTeacher: false};
        case types.GET_TEACHER_FAIL:
            return { ...state, gettingTeacher: false };
        case types.GET_TEACHER_JOBS_APPLIED_FOR:
            return { ...state, gettingTeacherJobsAppliedFor: true };
        case types.GET_TEACHER_JOBS_APPLIED_FOR_SUCCESS:
            return { ...state, teacherJobsAppliedFor: action.payload, gettingTeacherJobsAppliedFor: false};
        case types.GET_TEACHER_JOBS_APPLIED_FOR_FAIL:
            return { ...state, gettingTeacherJobsAppliedFor: false, gettingTeacherJobsAppliedForFailed: true };
        case types.GET_TEACHER_JOBS_APPLIED_FOR_DETAILED:
            return { ...state, gettingTeacherJobsAppliedForDetailed: true };
        case types.GET_TEACHER_JOBS_APPLIED_FOR_DETAILED_SUCCESS:
            return { ...state, teacherJobsAppliedForDetailed: action.payload, gettingTeacherJobsAppliedForDetailed: false};
        case types.GET_TEACHER_JOBS_APPLIED_FOR_DETAILED_FAIL:
            return { ...state, gettingTeacherJobsAppliedForDetailed: false };
        case types.TEACHER_APPLY_JOB: {
            const applyingCopy = state.teacherJobsApplyingFor.slice();
            applyingCopy.push(action.payload);
            return { ...state, teacherJobsApplyingFor: applyingCopy };
            }
        case types.TEACHER_APPLY_JOB_SUCCESS: {
            const appliedCopy = state.teacherJobsAppliedFor.slice();
            appliedCopy.push(action.payload);
            // remove this one from teacherJobsApplyingFor since it is now finished
            const applyingFiltered = state.teacherJobsApplyingFor.filter(e => e !== action.payload);
            return { ...state, teacherJobsAppliedFor: appliedCopy, teacherJobsApplyingFor: applyingFiltered };
        }
        case types.TEACHER_APPLY_JOB_FAIL:
            return state; // do something
        default:
            return state;
    }
};
