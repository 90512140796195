import { combineReducers } from 'redux';
import * as types from '../actions/types';
import AuthReducer from './AuthReducer';
import JobsReducer from './JobsReducer';
import DistrictsReducer from './DistrictsReducer';
import EmployerReducer from './EmployerReducer';
import ApplyReducer from './ApplyReducer';
import NewJobFormReducer from './NewJobFormReducer';
import AlertsReducer from './AlertsReducer';
import NewTeacherSetupReducer from './NewTeacherSetupReducer';
import ApplicationViewReducer from './ApplicationViewReducer';
import EmployerSettingsReducer from './EmployerSettingsReducer';
import GeoReducer from './GeoReducer';
import EmployerSignupReducer from './EmployerSignupReducer';
import TeacherSignUpReducer from './TeacherSignUpReducer';
import TeacherSetupReducer from './TeacherSetupReducer';
import TeachersReducer from './TeachersReducer';
import TeacherReducer from './TeacherReducer';
import TeacherRefLettersReducer from './TeacherRefLettersReducer';
import TeacherResumeReducer from './TeacherResumeReducer';
import TeacherTranscriptReducer from './TeacherTranscriptReducer';

const appReducer = combineReducers({
  auth: AuthReducer,
  jobs: JobsReducer,
  districts: DistrictsReducer,
  teachers: TeachersReducer,
  teacherRefLetters: TeacherRefLettersReducer,
  teacherResumes: TeacherResumeReducer,
  teacherTranscripts: TeacherTranscriptReducer,
  teacher: TeacherReducer,
  employer: EmployerReducer,
  employerSettings: EmployerSettingsReducer,
  apply: ApplyReducer,
  newJobForm: NewJobFormReducer,
  alerts: AlertsReducer,
  teacherSetupStatus: NewTeacherSetupReducer,
  applicationView: ApplicationViewReducer,
  geo: GeoReducer,
  employerSignup: EmployerSignupReducer,
  teacherSignup: TeacherSignUpReducer,
  teacherSetup: TeacherSetupReducer,
});

// https://stackoverflow.com/a/35641992/5605817
const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_USER_SUCCESS) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer;
