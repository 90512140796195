// TODO: not every function is being used in this file
import django from '../../apis/django';
import * as types from './types';
import { auth } from '../../firebase/Firebase';
import store from '..';
import { getAndSaveDistrict } from './districtData';
import { getEmployer } from './employers';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const getTeacherByUID = async (uid) => {
  try {
    const config = await getRequestConfig();
    const response = await django.get(`/teachers/${uid}/`, config);
    return response.data;
  } catch (err) {
    console.error(err);
    console.error('There was a problem looking up a teacher by the provided ID');
    return null;
  }
};

export const getTeacher = async () => {
    const { uid } = auth.currentUser;
    const teacher = await getTeacherByUID(uid);
    if (teacher) {
      store.dispatch({ type: types.GET_USER_DATA, payload: teacher });
      return teacher;
    } else {
      return null;
    }
};

// Checks if the user is an employee and gets data if the user is an employee
const isEmployerCheck = async (employerData) => {
  try {
    // If there is a row in the Employers table for this user, then the user is an employer
    if (employerData && employerData.isVerified) {
      // console.log('isEmployerCheck: This is a verified employer');
      await getAndSaveDistrict(employerData.district);
      store.dispatch({ type: types.GET_EMPLOYER_DATA, payload: employerData });
      store.dispatch({ type: types.SET_USER_IS_EMPLOYER, payload: true });
    } else { // Else, the user is not an employer
      // console.log('isEmployerCheck: Not an employer - or at least not verified');
      store.dispatch({ type: types.SET_USER_IS_EMPLOYER, payload: false });
    }
    return (employerData != null);
  } catch (err) {
    console.error(err);
    console.error('There was a problem checking if the user is an employer.');
    store.dispatch({ type: types.SET_USER_IS_EMPLOYER, payload: false });
    return false;
  }
};

let response = ""
export const updateUserClaims = async (user) => {
  try {
    const config = await getRequestConfig();
    response = await django.get(`/claims/update/`, config);
    if (response.data.message == 'CLAIMS_REFRESH') {
      // We need to call user.getIdToken(forceRefresh?: boolean) to get a new token
      // Then replace the token in the provided user object with the new value
      // https://firebase.google.com/docs/reference/js/auth.user.md#usergetidtoken
      try {
        await user.user.getIdToken(true);
      } catch (error) {
        console.error('There was an error refreshing the ID token')
        return null
      }
    }
    return null;
  } catch (error) {
    console.error('There was an error updating claims');
    return null;
  }
}

export const loginUser = ({ email, password }) => async (dispatch) => {
  try {
    dispatch({ type: types.LOGIN_USER });
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
    const user = await auth.signInWithEmailAndPassword(email, password);
    const employerData = await getEmployer(auth.currentUser.uid);
    const isEmployer = await isEmployerCheck(employerData);

    // Get the user's current claims
    // Update custom claims for this user in Firebase
    await updateUserClaims(user);

    if (!isEmployer) {
      await getTeacher();
    }
    store.dispatch({ type: types.LOGIN_USER_SUCCESS, payload: user.user });
  } catch (err) {
    console.error(err);
    console.error('There was a problem logging in.');
    dispatch({ type: types.LOGIN_USER_FAIL });
    return err.message;
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await auth.signOut();
    dispatch({ type: types.LOGOUT_USER_SUCCESS });
  } catch (err) {
    console.error(err);
    console.error('There was a problem logging out.');
    dispatch({ type: types.LOGIN_USER_FAIL });
  }
};

export const automaticLogin = async () => {
  try {
    // Indicate that we are attempting a login. This prevents the job list
    // from displaying anything until we know the status of the login.
    store.dispatch({ type: types.LOGIN_USER });
    const user = await new Promise((resolve, reject) => {
      try {
        auth.onAuthStateChanged(res => {
          if (res) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      } catch (err) {
        reject(err);
      }
    });

    if (user) {
      const employerData = await getEmployer(auth.currentUser.uid)
      const isEmployer = await isEmployerCheck(employerData);
      const isVerified = isEmployer && employerData.isVerified;

      if (isEmployer && isVerified) {
        const { uid } = auth.currentUser;
        const employerData = await getEmployer(uid);
        store.dispatch({ type: types.GET_EMPLOYER_DATA, payload: employerData });
        await getAndSaveDistrict(employerData.district);
      } else {
        await getTeacher();
      }
      store.dispatch({ type: types.LOGIN_USER_SUCCESS, payload: { user }.user });
    } else {
      // Not a user
      // Finalize the login attempt as failed, so that the job list can render
      store.dispatch({ type: types.LOGIN_USER_FAIL });
    }
  } catch (err) {
    console.error(err);
    console.error('There was a problem trying to automatically login');
  }
};
