import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

class FileUploadProgress extends Component {
  render() {
    const { type, teacherTranscripts, teacherRefLetters, teacherResumes } = this.props;
    var stateObj;
    if (type == 'transcripts') {
        stateObj = teacherTranscripts
    } else if (type == 'references') {
        stateObj = teacherRefLetters
    } else if (type == 'resumes') {
        stateObj = teacherResumes
    }
    if(!stateObj.uploadStarted) {
        return null
    } else {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={stateObj.uploadProgress} />
                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(stateObj.uploadProgress)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }
  }
}

const mapStateToProps = state => ({
    teacherRefLetters: state.teacherRefLetters,
    teacherResumes: state.teacherResumes,
    teacherTranscripts: state.teacherTranscripts,
  });
  
  export default connect(
    mapStateToProps,
    {
    }
  )(FileUploadProgress);