import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createEmployer } from '../../state/actions/employerSignUp';
import CustomButton from '../CustomButton/CustomButton';
import PageLayout from '../../pages/PageLayout/PageLayout';

let coordinatorEmail;
const EmployerSignUpSchema = Yup.object({
  lea: Yup
    .string()
    // .typeError('District LEA must be a number')
    // .positive('District LEA must be a positive number')
    .required('Please enter a valid LEA number')
    .min(6, 'District LEA must be at least 6 characters')
    .max(7, 'District LEA must not be more than 7 characters')
    .matches(/^[0-9]+$/, "District LEA must be only digits")
    .matches(/^[^0].*/, "District LEA cannot start with zero"),
    fullName: Yup
      .string()
      .required('Please enter your full name')
      .min(3, 'Please enter at least 3 characters')
      .max(100, 'Maximum 100 characters'),
    accountEmail: Yup
      .string()
      .required('Please enter a valid email for this account')
      .email('Invalid email')
      .max(100, 'Maximum 100 characters'),
    password: Yup
      .string()
      .required('Please enter a password')
      .min(6, 'Password must be at least 6 characters'),
});

// TODO: confirm password (https://github.com/jaredpalmer/formik/issues/90)

function EmployerSignUpForm(props) {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    employerSignup,
    touched,
  } = props;
  return (
    <PageLayout>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <h2>School district signup</h2>
        <Formik
          validationSchema={EmployerSignUpSchema}
          onSubmit={async (values, actions) => {
            props.createEmployer(values);
            actions.resetForm();
            actions.setSubmitting(false);
          }}
          initialValues={{
            lea: '',
            fullName: '',
            accountEmail: '',
            confirmEmail: '',
            password: ''
          }}
        >
          {formik => (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <Form.Group controlId="lea">
                  <Form.Label className={!formik.touched.lea ? "" : (formik.errors.lea ? "text-danger" : "")}>District LEA<br />
                    <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
                      Requires 6 or 7 digits<br />
                      ( E.g.: 7203 should be 7203000, and 0405 should be 405000 with the initial &apos;0&apos; excluded)
                    </span>
                  </Form.Label>
                  <Form.Control
                    placeholder="District LEA number"
                    // value={values.lea[0] === 0 ? values.lea.substr(1, values.lea.length) : values.lea} // this helps to ensure that 0 is eliminated.
                    minLength={6}
                    isInvalid={formik.errors.lea && formik.touched.lea}
                    isValid={!formik.errors.lea && formik.touched.lea}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('lea')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="lea" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="fullName">
                  <Form.Label className={!formik.touched.fullName ? "" : (formik.errors.fullName ? "text-danger" : "")}>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your Full Name"
                    isInvalid={formik.errors.fullName && formik.touched.fullName}
                    isValid={!formik.errors.fullName && formik.touched.fullName}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('fullName')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="fullName" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="accountEmail">
                  <Form.Label className={!formik.touched.accountEmail ? "" : (formik.errors.accountEmail ? "text-danger" : "")}>
                    Account Email <br />
                    <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
                      Used to log in to your account.<br />
                      ( Please use district associated email )
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account email"
                    name="accountEmail"
                    isInvalid={formik.errors.accountEmail && formik.touched.accountEmail}
                    isValid={!formik.errors.accountEmail && formik.touched.accountEmail}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('accountEmail')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="accountEmail" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="password">
                  <Form.Label className={!formik.touched.password ? "" : (formik.errors.password ? "text-danger" : "")}>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="password"
                      placeholder="Create a password"
                      isInvalid={formik.errors.password && formik.touched.password}
                      isValid={!formik.errors.password && formik.touched.password}
                      style={{ width: 250 }}
                      {...formik.getFieldProps('password')}
                    />
                  </InputGroup>
                  <ErrorMessage component="div" className="text-danger" name="password" />
                </Form.Group>
              </Row>
              <CustomButton
                  type="submit"
                  isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Signing up...' : 'Sign up'}
              </CustomButton>
            </Form>
          )}
        </Formik>
      </div>
    </PageLayout>
  );
}

export const exportCoordinatorEmail = () => coordinatorEmail;

const mapStateToProps = state => ({
  employerSignup: state.employerSignup,
});

export default connect(
  mapStateToProps,
  { createEmployer }
)(EmployerSignUpForm);
