import django from '../../apis/django';
import { auth } from '../../firebase/Firebase';
import * as types from './types';
import { getDistrictByLEA } from './districtData';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

let email;

// TODO: AB I would rather we create the user first, and then look up the district
// That way the district lookup endpoint can require authentication
// See https://github.com/synsesolutions/teach/issues/128
export const createEmployer = signupInfo => async dispatch => {
  try {
    const districtDataSnapshot = await getDistrictByLEA(signupInfo.lea);
    if (districtDataSnapshot) {
      await auth.createUserWithEmailAndPassword(signupInfo.accountEmail, signupInfo.password);

      const { uid } = auth.currentUser;

      const district = {
        firebaseAuthId: uid,
        userName: signupInfo.accountEmail,
        fullName: signupInfo.fullName,
        isVerified: false,
        resendVerification: false,
        isAdmin: false,
        district: districtDataSnapshot.id
      };
      email = districtDataSnapshot.coordinatorEmail;
      const config = await getRequestConfig();

      var response;
      try {
        response = await django.post('/employers/create/', district, config);
      } catch (err) {
        console.error(err);
        dispatch({
          type: types.CREATE_EMPLOYER_FAIL, payload: 'Sorry, there was an error storing user information.'
        })
        return '';
      }

      // TODO: Need error handling here, we can't dispatch success if there wasn't any
      dispatch({ type: types.CREATE_EMPLOYER_SUCCESS });

      return districtDataSnapshot.coordinatorEmail;
    }

    dispatch({
      type: types.CREATE_EMPLOYER_FAIL,
      // AB 5/14/22: TODO: This error message seems inaccurate. We're not
      // comparing coordinatorEmail, we're looking for LEA.
      // Also, it seems like arteachermatch@gmail.com should be from a
      // constant somewhere, not hard-coded into the message. In fact, the
      // whole error message should probably come from a constant.
      // TODO: It's also an information disclosure issue - the person submitting
      // the form should not be shown this email address. They didn't have it
      // and (as far as I can tell) should not have it.
      payload: `Error, the coordinator email we have for your district (${signupInfo.coordinatorEmail}) does not match the entered data. If you believe the data you entered to be correct, then please contact us at arteachermatch@gmail.com`,
    });

    return '';
  } catch (err) {
    console.error(err);
    console.error('There was a problem trying to create a district account.');
    dispatch({ type: types.CREATE_EMPLOYER_FAIL, payload: err.message });

    return '';
  }
};

export const updateEmployerVerification = (id) => async dispatch => {
  try {
    const config = await getRequestConfig();
    await django.patch(`/employers/${id}/`, { resendVerification: true }, config);
    dispatch({ type: types.UPDATE_EMPLOYER_VERIFICATION });
  } catch (err) {
    console.error(err);
    console.error('There was a problem updating employers to resend verification email');
  }
};

export const coordinatorEmail = () => email;
