import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { getPostings, getQuestions, getAdminPostings } from '../../state/actions/postings';
import { startNewPosting, startAdminJobPosting, cancelNewJob, cancelAdminJob } from '../../state/actions/newJobForm';
import { logoutUser } from '../../state/actions/auth';
import Posting from '../../components/Posting/Posting';
import NewJobPosting from '../../components/NewJobPosting/NewJobPosting';
import AdminJobPosting from '../../components/NewJobPosting/AdminPosting';
import NewJobPostedAlert from '../../components/NewJobPostedAlert/NewJobPostedAlert';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomCard from '../../components/CustomCard/CustomCard';
import SkeletonLoadingCard from '../../components/SkeletonLoadingCard/SkeletonLoadingCard';
import LandingComponent from '../../components/LandingComponent/LandingComponent';
import Theme from '../../theme/Theme';
import PageLayout from '../PageLayout/PageLayout';

class EmployerPostingList extends Component {
  state ={
    adminPost: true,
  }

  componentDidMount() {
    const { user, history, userIsEmployer } = this.props;
    if ((user == null && this.props.isLoggingIn == false) || userIsEmployer == false) {
      history.push('/');
    // To get here, userIsEmployer has to be not false, but it could be null
    } else if (user !== null && this.props.isLoggingIn == false && userIsEmployer == true) {
      this.props.getAdminPostings();
      this.props.getPostings();
      this.props.getQuestions();
    }
  }

  componentDidUpdate() {
    const { user, history, userIsEmployer } = this.props;
    if ((user == null && this.props.isLoggingIn == false) || userIsEmployer == false) {
      history.push('/');
    } else if (user !== null && this.props.isLoggingIn == false && userIsEmployer == true) {
      if (this.props.gettingAdminPostings == null) {
        this.props.getAdminPostings();
      }
      if (this.props.gettingPostings == null) {
        this.props.getPostings();
      }
      if (this.props.gettingEmployerQuestions == null) {
        this.props.getQuestions();
      }
    }
  }

  onNewPostClick = () => {
    if (this.props.showNewPostingForm) {
      this.props.cancelNewJob();
    } else {
      this.props.startNewPosting();
    }
  };

  onNewAdminPostClick = () => {
    if (this.props.hasStartedAdminPosting) {
      this.props.cancelAdminJob();
    } else {
      this.props.startAdminJobPosting();
    }
  };

  renderWelcome = () => {
    const name = this.props.employerData
      ? this.props.employerData.name
      : 'teachers';
    return (
      <div>
        <div>
          <h2 style={{ fontWeight: 600, display: 'inline' }}>
            A FREE resource designed to help{' '}
            <span
              style={{
                color: Theme.ThemePrimary,
                fontWeight: 600,
                display: 'inline',
              }}
            >
              {name}
            </span>{' '}
            find great teachers.
          </h2>
        </div>
        <p
          style={{ visibility: this.props.employerData ? 'visible' : 'hidden' }}
        >
          Below are the applicants for the jobs you&#39;ve posted.
        </p>
      </div>
    );
  };

  renderNewPostSubmission = () => (
    <CustomCard>
      <CardActions>
        <CustomButton
          onClick={this.onNewPostClick}
          variant={
            this.props.showNewPostingForm
              ? 'danger-outlined'
              : 'default-outlined'
          }
        >
          {this.props.showNewPostingForm ? 'Cancel' : 'Start new job posting'}
        </CustomButton>
      </CardActions>
      <Collapse in={this.props.showNewPostingForm}>
        <CardContent>
          <NewJobPosting
            employerData={this.props.employerData}
            externalEmployerData={this.props.externalEmployerData}
          />
        </CardContent>
      </Collapse>
    </CustomCard>
  );

  renderNewAdminPostSubmission = posting => (
    <CustomCard key={posting.id}>
      <h4>{ posting.title}</h4>
      <p>{posting.description}</p>
      <CardActions>
        <CustomButton
          onClick={this.onNewAdminPostClick}
          variant={
            this.props.hasStartedAdminPosting
              ? 'danger-outlined'
              : 'default-outlined'
          }
        >
          {this.props.hasStartedAdminPosting ? 'Cancel' : 'Complete new job posting'}
        </CustomButton>
      </CardActions>
      <Collapse in={this.props.hasStartedAdminPosting}>
        <CardContent>
          <AdminJobPosting
            jobTitle={posting.title}
            jobId={posting.id}
            jobDescription={posting.description}
            employerData={this.props.employerData}
            externalEmployerData={this.props.externalEmployerData}
            expirationDate={this.props.expirationDate}
          />
        </CardContent>
      </Collapse>
    </CustomCard>
  );

  renderPosting = posting => {
    const { applicants } = this.props;
    let applicantsList = [];
    if (applicants && applicants[posting.id]) {
      applicantsList = applicants[posting.id];
    }

    // TODO: optimize to only send the relevant resume links

    return (
      <Posting
        posting={posting}
        applicants={applicantsList}
        resumeLinks={this.props.resumeLinks}
        key={posting.id}
        questions={this.props.questions}
      />
    );
  };

  renderAdminPosting = posting => {
    const { applicants } = this.props;
    let applicantsList = [];
    if (applicants && applicants[posting.id]) {
      applicantsList = applicants[posting.id];
    }

    // TODO: optimize to only send the relevant resume links

    return (
      <div>
        <text>Jobs awaiting completion</text>
        <Posting
          posting={posting}
          applicants={applicantsList}
          resumeLinks={this.props.resumeLinks}
          key={posting.id}
          questions={this.props.questions}
        />
      </div>
    );
  };

  renderCards = () => {
    const { postings, adminPostings, gettingPostings, gettingAdminPostings } = this.props;
    if ((gettingPostings != false || gettingAdminPostings != false)) {
      return (
        <div>
          <SkeletonLoadingCard />
          <SkeletonLoadingCard />
          <SkeletonLoadingCard />
        </div>
      );
    }
    return (
      <div>
        {this.renderNewPostSubmission()}
        {adminPostings.map(this.renderNewAdminPostSubmission)}
        {/* {adminPostings.map(this.renderPosting)} */}
        {postings.map(this.renderPosting)}
      </div>
    );
  };

  render() {
    return (
      <PageLayout>
        <NewJobPostedAlert />
        <LandingComponent welcomeMessage={this.renderWelcome()} />
        {this.renderCards()}
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  userIsEmployer: state.auth.userIsEmployer,
  isLoggingIn: state.auth.isLoggingIn,
  postings: state.employer.postings,
  gettingPostings: state.employer.gettingPostings,
  questions: state.employer.questions,
  gettingEmployerQuestions: state.employer.gettingEmployerQuestions,
  applicants: state.employer.applicants,
  resumeLinks: state.employer.resumeLinks,
  showNewPostingForm: state.newJobForm.showNewPostingForm,
  hasStartedAdminPosting: state.newJobForm.hasStartedAdminPosting,
  employerData: state.employer.employerData,
  externalEmployerData: state.employer.externalEmployerData,
  adminPostings: state.employer.adminPostings,
  gettingAdminPostings: state.employer.gettingAdminPostings,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getPostings,
      getAdminPostings,
      getQuestions,
      startNewPosting,
      startAdminJobPosting,
      cancelNewJob,
      cancelAdminJob,
      logoutUser,
    }
  )(EmployerPostingList)
);
