import { auth } from '../../firebase/Firebase';
import * as types from './types';
import store from '..';
// import { getQuestionsByEmployer } from './questions';
import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

const getApplicants = (jobId, applicants) => {
  const payload = { [jobId]: applicants };
  store.dispatch({ type: types.GET_APPLICANTS_SUCCESS, payload });
};

// AB 5/21/22: This function is duplicated in src/state/actions/auth.js
// where it is called by the automaticLogin() function.
// AB 10/31/22: I think we should JSON.parse() the fields we know are JSON
// before we save them in state. Or maybe we should do that WHEN we 
// save them in state, in GET_POSTINGS_SUCCESS.
export const getPostings = () => async dispatch => {
  if (auth.currentUser) {
    try {
      dispatch({ type: types.GET_POSTINGS });
      const { uid } = auth.currentUser;
      // Bring back jobs for the logged-in employer along with any applications
      const config = await getRequestConfig();
      const postingResponse = await django.get(`/jobs/employer/${uid}/applications/`, config);
      const postings = postingResponse.data
      // While it may seem odd to take part of posting and put it elsewhere in state,
      // it actually makes things much easier in Postings.
      var applicants = {}
      postings.forEach(posting => {
        applicants[posting.id]=posting.applications;
      });
      dispatch({ type: types.GET_APPLICANTS_SUCCESS, payload: applicants });
      dispatch({ type: types.GET_POSTINGS_SUCCESS, payload: postings });
    } catch (err) {
      console.error(err);
      console.error('There was an error getting postings.');
      dispatch({ type: types.GET_POSTINGS_FAIL });
    }
  }
};

export const getAdminPostings = () => async dispatch => {
  if (auth.currentUser) {
    try {
      dispatch({ type: types.GET_ADMIN_POSTINGS });
      const { uid } = auth.currentUser;
      const config = await getRequestConfig();
      const response = await django.get(`/scrapedjobs/employer/${uid}/`, config);
      dispatch({ type: types.GET_ADMIN_POSTINGS_SUCCESS, payload: response.data });
    } catch (err) {
      console.error(err);
      console.error('There was a problem getting job postings.');
      dispatch({ type: types.GET_ADMIN_POSTINGS_FAIL });
    }
  }
};

export const getQuestions = () => async dispatch => {
  if (auth.currentUser) {
    try {
      dispatch({ type: types.GET_EMPLOYER_QUESTIONS });
      const { uid } = auth.currentUser;
      const config = await getRequestConfig();
      const questionResponse = await django.get(`/essayquestions/employer/${uid}/`, config);
      const questions = questionResponse.data;
      dispatch({ type: types.GET_EMPLOYER_QUESTIONS_SUCCESS, payload: questions });
    } catch (err) {
      console.error(err);
      console.error('There was a problem trying to get questions');
      dispatch({ type: types.GET_EMPLOYER_QUESTIONS_FAIL });
    }
  }
};

export const updatePosting = (posting, postingValues) => async dispatch => {
  var selectedLicensure = [];
  if (Array.isArray(postingValues.selectedLicensure) && postingValues.selectedLicensure.length > 0) {
    selectedLicensure = JSON.stringify(postingValues.selectedLicensure)
  }

  var selectedQuestions = [];
  if (postingValues.selectedQuestions && postingValues.selectedQuestions.length > 0 && postingValues.selectedQuestions[0] != '') {
    selectedQuestions = JSON.stringify(postingValues.selectedQuestions)
  }

  var selectedGradeLevels = [];
  if (postingValues.selectedGradeLevels && postingValues.selectedGradeLevels.length > 0 && postingValues.selectedGradeLevels[0] != '') {
    selectedGradeLevels = JSON.stringify(postingValues.selectedGradeLevels)
  }

  // postingPayload is for what we want to send to the backend
  const postingPayload = {
    title: postingValues.title,
    description: postingValues.description,
    selectedLicensure,
    selectedPartOrFullTime: postingValues.selectedPartOrFullTime,
    selectedQuestions,
    selectedGradeLevels,
    expirationDate: postingValues.expirationDate,
    externalLink: postingValues.externalLink
  }

  // postingUpdated is for updating state with the edited values
  // We do that instead of fetching the updates values back from the backend
  const postingUpdated = {
    id: posting.id,
    title: postingValues.title,
    description: postingValues.description,
    selectedLicensure: postingValues.selectedLicensure,
    selectedPartOrFullTime: postingValues.selectedPartOrFullTime,
    selectedQuestions: postingValues.selectedQuestions,
    selectedGradeLevels: postingValues.selectedGradeLevels,
    expirationDate: postingValues.expirationDate,
    externalLink: postingValues.externalLink
  }
  try {
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, postingPayload, config);
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].title = postingUpdated.title;
    employerPostingsCopy[foundIndex].description = postingUpdated.description;
    employerPostingsCopy[foundIndex].selectedLicensure = postingUpdated.selectedLicensure;
    employerPostingsCopy[foundIndex].selectedPartOrFullTime = postingUpdated.selectedPartOrFullTime;
    employerPostingsCopy[foundIndex].selectedQuestions = postingUpdated.selectedQuestions;
    employerPostingsCopy[foundIndex].selectedGradeLevels = postingUpdated.selectedGradeLevels;
    employerPostingsCopy[foundIndex].expirationDate = postingUpdated.expirationDate;
    employerPostingsCopy[foundIndex].externalLink = postingUpdated.externalLink;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
    return true
  } catch (error) {
    dispatch({ type: types.UPDATE_EMPLOYER_POSTING_FAIL });
    console.error(error);
    console.error('There was a problem updating the posting.');
    return false
  }
}

export const cancelUpdatePosting = () => async dispatch => {
  dispatch({ type: types.UPDATE_EMPLOYER_POSTING_CANCEL });
}

export const updateJobPostingStatus = (posting, isClosed) => async (dispatch) => {
  try {
    const updatePayload = {
      isClosed, dateClosed: Date.now()
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config);
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].isClosed = isClosed;
    employerPostingsCopy[foundIndex].dateClosed = Date.now();
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.error(err);
    console.error('There was a problem trying to close the job');
  }
};