import * as types from '../actions/types';

const INITIAL_STATE = {
  showNewPostingForm: false,
  title: '',
  description: '',
  externalLink: '',
  isPosting: false,
  backendError: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_NEW_JOB_POSTING:
      return { ...state, showNewPostingForm: true };
    case types.START_ADMIN_JOB_POSTING:
      return { ...state, hasStartedAdminPosting: true };
    case types.CHANGE_NEW_JOB_LINK:
      return { ...state, externalLink: action.payload };
    case types.CHANGE_NEW_JOB_DESCRIPTION:
      return { ...state, description: action.payload };
    case types.CHANGE_NEW_JOB_TITLE:
      return { ...state, title: action.payload };
    case types.POST_NEW_JOB:
      return { ...state, isPosting: true };
    case types.CANCEL_NEW_JOB_POSTING:
      return { ...INITIAL_STATE };
    case types.CANCEL_ADMIN_JOB_POSTING:
      return { ...INITIAL_STATE };
    case types.POST_NEW_JOB_SUCCESS:
      return { ...INITIAL_STATE };
    case types.POST_NEW_JOB_FAIL:
      return { ...state, backendError: action.payload, isPosting: false };
    default:
      return state;
  }
};
